<app-header-technicien-chauffeur [Titre]="'Liste des animaux à incinérer'"></app-header-technicien-chauffeur>
<div class="Container">
    <div id="ContainerScan">
        <button kendoButton id="btnScan" (click)="this.OuvrirQrCodeMulti()">Validation crémation <img src="../../../../assets/TechnicienChauffeur/ScanMultiple.svg" alt=""></button>
    </div>
    <div class="ContainerFournee">
        <button (click)="this.CreationFournee()" id="CreationFournee">
            <span id="txtCreation">Créer une fournée</span>
            <span id="arrow">➜</span>
        </button>
        <br>
        <p id="Titre">Animaux dans la chambre froide</p>
        <br>
        <app-carte-animal [Commandes]="Commandes"></app-carte-animal>
    </div>
</div>