import { Component, OnInit } from '@angular/core';
import { Produit } from '../../../_models/Produit';
import { ProduitService } from '../../../_services/ProduitService';

@Component({
  selector: 'app-header-client-boutique-mobile',
  templateUrl: './header-client-boutique-mobile.component.html',
  styleUrls: ['./header-client-boutique-mobile.component.scss']
})
export class HeaderClientBoutiqueMobileComponent implements OnInit {

  loading: boolean = false;
  nomCategories: string[] = []
  produits: Produit[] = []

  constructor(private _ProduitService: ProduitService) { }

  async ngOnInit(): Promise<void> {
    this.loading = true

    this.nomCategories = (await this._ProduitService.GetProduitCategories()).objet.sort()

    this.loading = false
  }

  async SetCategorie(nomCategorie: string, isRedirection: boolean) {

    this._ProduitService.SetNomCategorie(nomCategorie)

    //Aller chercher l'url actuelle
    let url = window.location.href

    if (!url.includes("/client/boutique/produits")) {
      window.location.href = "/client/boutique/produits?categorie=" + nomCategorie
    }

    this.toggleMenu()
  }

  toggleMenu() {
    const menu = document.getElementById("menu2")!;
    menu.style.display = menu.style.display === "block" ? "none" : "block";
  }

}
