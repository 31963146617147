<div id="header">
    <div id="Text">
        <p> <a class="link" href="https://www.monphoenix.fr/qui-sommes-nous/" target="_blank">Qui sommes-nous</a></p>
        <p> <a class="link" href="https://www.monphoenix.fr/" target="_blank">Nos prestations</a></p>
        <p> <a class="link" href="/client/boutique" *ngIf="!this.commande.isCremationTermine || this.client.email == 'sandrine.hamelet@hotmail.fr'">La boutique</a></p>
    </div>
    <div id="logo">
        <img src="https://www.monphoenix.fr/wp-content/uploads/2024/11/logo-horizontal.png" (click)="GoToAccueil()"
            alt="Logo" id="LogoPhoenix">
    </div>
    <div id="Btn">
        <a id="LienMemorial" (click)="GoToMemorial()" target="_blank" rel="noopener noreferrer">Rendre hommage à mon animal</a>

        <div (click)="GoToMonCompte()" id="ContainermonCompte">
            <img src="../../../assets/Utilisateur/MonCompte.png" alt="MonCompte" id="LogoMonCompte">
            <div id="TextMonCompte">
                <p id="TxtMonCompte">MON COMPTE</p>
                <p id="TxtParametres">Paramètres</p>
            </div>
        </div>
        <div (click)="GoToPanier()" id="ContainermonCompte">
            <img src="../../../assets/Utilisateur/Panier.png" alt="panier" id="LogoPanier">
            <div id="TextMonCompte">
                <p id="TxtMonCompte">Panier</p>
                <p id="TxtParametres">{{this._PanierService.GetTotalPanier()}} €</p>
            </div>
        </div>

    </div>
</div>