import { Component, ViewChild } from '@angular/core';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { GlobalService } from '../../_services/GlobalService';
import { UtilisateurService } from '../../_services/UtilisateurService';
import { DataModelLinkTOTP } from '../../_models/DataModelController/DataModelLinkTOTP';
import { PasswordUpdateModel } from '../../_models/DataModelController/PasswordUpdateModel';

@Component({
  selector: 'app-mon-compte-admin',
  templateUrl: './mon-compte-admin.component.html',
  styleUrls: ['./mon-compte-admin.component.scss']
})
export class MonCompteAdminComponent {
  loading: boolean = false;

  @ViewChild("ancienMdpBox") public ancienMdpBox!: TextBoxComponent;
  @ViewChild("nouveauMdpBox") public nouveauMdpBox!: TextBoxComponent;
  @ViewChild("confirmationMdpBox") public confirmationMdpBox!: TextBoxComponent;

  public LienAuthentification: string = "";
  public codeManuel: string = "";
  isMobile: boolean = false;
  public nouveauMdp: string = "";
  public ancienMDP: string = "";
  public confirmationMdp: string = "";
  public mdpValide: boolean = true;
  public nouveauMdpValide: boolean = false;
  public messageErreur: string = "";
  public messageSucces: string = "";
  public messageErreur2FA: string = "";
  public messageSucces2FA: string = "";

  constructor(public _GlobalService: GlobalService, private _UtilisateurService: UtilisateurService) { }

  ngOnInit(): void {
    this.chargeAdmin();
  }

  public ngAfterViewInit(): void {
    this.ancienMdpBox.input.nativeElement.type = "password";
    this.nouveauMdpBox.input.nativeElement.type = "password";
    this.confirmationMdpBox.input.nativeElement.type = "password";
  }

  private async chargeAdmin() {
    this._GlobalService.utilisateur = (await this._UtilisateurService.GetInfosUser()).objet;

    let data: DataModelLinkTOTP = new DataModelLinkTOTP();
    data.isMobile = this.isMobile;
    data.issuer = "MonPhoenix"
    data.isEmail = false;
    let result = (await this._UtilisateurService.GetLinkTOTP(data)).message.split("LIMIT")
    this.LienAuthentification = result[0]
    this.codeManuel = result[1]
  }

  //enregistre le nouveau mot de passe et les réglages
  public async modifieAdmin() {
    //modification du mot de passe
    if (this.mdpValide && this.nouveauMdp.length > 0) {
      //on vérifie que la paire nouveau mot de passe est correct
      if (this.nouveauMdpValide && this.verifieNouveauMdp()) {

        this.messageErreur = ""
        let data: PasswordUpdateModel = {
          AncienMDP: this.ancienMDP,
          NouveauMDP: this.nouveauMdp
        }

        // //enregistre le nouveau mot de passe
        let retour = await this._UtilisateurService.ModifieMDP(data); //Pas la bonne route

        if (retour.statusCode == 200) {
          this.messageSucces = "Les modifications ont été enregistrées avec succès."
        } else {
          this.messageErreur = retour.messageException;
        }

      }
      else {
        if (!this.verifieNouveauMdp()) {
          this.messageErreur = "Le mot de passe et sa confirmation ne correspondent pas";
        }
        else {
          this.messageErreur = "Les champs ne sont pas renseignés correctement.";
        }

      }
    }

  }

  public async ModifieDoubleAuthentificationAdmin() {
    let retour = await this._UtilisateurService.ModifieDoubleAuthentificationAdmin(this._GlobalService.utilisateur!.twoFactorEnabled);

    if (retour.statusCode == 200) {
      this.messageSucces2FA = "Les modifications ont été enregistrées avec succès."
    } else {
      this.messageErreur2FA = retour.messageException;
    }
  }

  onChangeMdp(e: any) {
    this.nouveauMdpValide = false;
    let retour = e.match("^((?=\\S*?[A-Z])(?=\\S*?[a-z])(?=\\S*?[0-9]).{7,})\\S");
    if (retour != null && e.length > 1) this.nouveauMdpValide = true;
  }

  verifieNouveauMdp(): Boolean {
    if (this.nouveauMdp == this.confirmationMdp) {
      return true;
    } else {
      return false
    }
  }

  ShowPassword(id: number) {
    //Prendre le type de l'input avec son id
    let input: TextBoxComponent | null = null;
    switch (id) {
      case 1:
        input = this.ancienMdpBox;
        break;
      case 2:
        input = this.nouveauMdpBox;
        break;
      case 3:
        input = this.confirmationMdpBox;
        break;
    }
    input!.input.nativeElement.type = input!.input.nativeElement.type === "password" ? "text" : "password";
  }
}
