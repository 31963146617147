import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../_services/GlobalService';
import { SocieteService } from '../../_services/SocieteService';
import { ProduitService } from '../../_services/ProduitService';
import { Produit } from '../../_models/Produit';
import { PanierModel } from '../../_models/PanierModel';
import { PanierService } from '../../_services/PanierService';
import { VariationProduitTaille } from '../../_models/VariationProduitTaille';
import { VariationProduitCouleur } from '../../_models/VariationProduitCouleur';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-fiche-produit-client',
  templateUrl: './fiche-produit-client.component.html',
  styleUrls: ['./fiche-produit-client.component.scss']
})
export class FicheProduitClientComponent implements OnInit {

  IdProduit: number;
  produits: Produit[] = [new Produit()];
  produit: Produit = new Produit();
  Quantite: number[] = [1, 2, 3,];
  QuantiteSelect: number = 1
  messageErreurInformation: string = ""
  loading: boolean = false
  CouleurSelect: VariationProduitCouleur = new VariationProduitCouleur()
  imageProduit: string = ""
  IsMobile: boolean = false

  constructor(private _DeviceDetector: DeviceDetectorService, public _GlobalService: GlobalService, private actRoute: ActivatedRoute, private _ProduitService: ProduitService, private _PanierService: PanierService) {
    this.IdProduit = this.actRoute.snapshot.params['Id'];
  }

  async ngOnInit(): Promise<void> {
    this.loading = true

    this.IsMobile = this._DeviceDetector.isMobile()

    //Aller chercher les produits
    this.produits = (await this._ProduitService.GetAllProduits()).objet;

    //Affecter le produit
    this.produit = this.produits.find(x => x.id == this.IdProduit)!

    //Affecter l'image du produit
    this.imageProduit = this.produit.imageUrl

    //Affecter la taille par défaut
    if (this.produit.variationProduitsTailles.length > 0) {
      this.produit.variationProduitsTailles.sort((a, b) => {
        // Extraire les nombres des chaînes et les convertir en entiers
        const tailleA = parseInt(a.taille.replace(/\D/g, ''), 10);
        const tailleB = parseInt(b.taille.replace(/\D/g, ''), 10);

        return tailleA - tailleB;
      });
      this.produit.Taille = this.produit.variationProduitsTailles[0]
    }

    //Checker si des variations de couleurs sont présentes
    if (this.produit.variationProduitsCouleurs.length > 0) {
      this.CouleurSelect = this.produit.variationProduitsCouleurs[0]
    }

    //Supprimer le produit de la liste
    this.produits = this.produits.filter(x => x.id != this.IdProduit)

    //Limiter à 4 produits
    this.produits = this.produits.slice(0, 4)

    this.loading = false

  }

  AjouterPanier() {
    const done = document.querySelector(".done") as HTMLElement;

    let isProduitInPanier = true;

    if (this.QuantiteSelect > 0) {
      //Ajouter l'id produit, la taille et la quantité au panier
      let Panier: PanierModel[] = [];
      Panier.push({ idProduit: this.IdProduit, taille: this.produit.Taille, quantite: this.QuantiteSelect, couleur: this.CouleurSelect })

      //Récupérer le panier existant
      let PanierExistant: PanierModel[] = JSON.parse(localStorage.getItem("Panier")!)

      //Si le panier existe
      if (PanierExistant != null && PanierExistant.length > 0) {

        //Si le pdouit existe déjà, ajouter la quantité
        let produitExistant = PanierExistant.find(x => x.idProduit == this.IdProduit && x.taille == this.produit.Taille)

        if (produitExistant) {
          //Si le produit n'est pas une crémation
          if (this.produit.produitCategories.some(cat => cat.categorieProduit.nom != "Crémation")) {
            //ajouter la quantité
            produitExistant.quantite += this.QuantiteSelect

            //Supprimer le produit dans le panier
            PanierExistant = PanierExistant.filter(x => x.idProduit != this.IdProduit)

            //le remettre dans le panier
            PanierExistant.push(produitExistant)

            //Sauvegarder le panier
            localStorage.setItem("Panier", JSON.stringify(PanierExistant));
          } else {
            isProduitInPanier = false
          }
        } else {
          //Ajouter le produit au panier
          PanierExistant.push({ idProduit: this.IdProduit, taille: this.produit.Taille, quantite: this.QuantiteSelect, couleur: this.CouleurSelect })

          //Sauvegarder le panier
          localStorage.setItem("Panier", JSON.stringify(PanierExistant));
        }

      } else {
        //Créer le panier
        localStorage.setItem("Panier", JSON.stringify(Panier));
      }

      if (isProduitInPanier) {
        this._PanierService.RefreshPanier()

        //Faire la transition sur le boutton
        done.style.transform = "translate(0px)";
      }

    } else {
      this.messageErreurInformation = "Veuillez saisir une quantité"
    }
  }

  IsProduitTailleModifiable() {
    return this.produit.variationProduitsTailles.length > 0
  }

  IsProduitCouleurModifiable() {
    return this.produit.variationProduitsCouleurs.length > 0
  }

  IsProduitQuantiteModifiable() {
    return !this.produit.nom.toLowerCase().includes("crémation")
  }

  getPrixProduit(): number {
    if (this.produit.variationProduitsTailles.length > 0) {
      return this.produit.Taille.prix
    } else {
      return this.produit.prix
    }
  }

  getImageProduit(): string {
    if (this.produit.variationProduitsCouleurs.length > 0) {
      return this.CouleurSelect.imageUrl
    } else {
      return this.produit.imageUrl
    }
  }

  UpdatePhotoProduit(event: any) {
    this.imageProduit = event.imageUrl
    this._PanierService.setCouleur(this.produit, event)
  }

  GoToAccueil() {
    location.href = "/client/accueil";
  }

  GoToCategorie(nomcategorie: string) {
    location.href = "/client/boutique/produits?categorie=" + nomcategorie;
  }

}