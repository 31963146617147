import { Component, OnInit } from '@angular/core';
import { Tournee } from '../../../_models/Tournee';
import { TourneeService } from '../../../_services/TourneeService';
import { TourneeEtablissement } from '../../../_models/TourneeEtablissement';

@Component({
  selector: 'app-chauffeur-accueil',
  templateUrl: './chauffeur-accueil.component.html',
  styleUrls: ['./chauffeur-accueil.component.scss']
})
export class ChauffeurAccueilComponent implements OnInit {

  Tournees: TourneeEtablissement[] = new Array<TourneeEtablissement>()
  titre: string = "Liste des sites de la tournée du \n " + new Date().toLocaleDateString()
  messageSuccesInformation :string =""

  constructor(private _TourneeService: TourneeService) { }

  async ngOnInit(): Promise<void> {
    this.Tournees = (await this._TourneeService.GetAllTournees(new Date())).objet.tourneeEtablissements
  }

  async DechargementTournee() {
    let reponse = await this._TourneeService.DechargerTournee()

    this.messageSuccesInformation = reponse.message
  }

}
