<div class="popup-overlay">
    <div class="popup-content">
      <h3>Confirmation</h3>
      <p>Êtes-vous sûr de vouloir supprimer cette tournée ?</p>
      <div class="popup-actions">
        <button (click)="Annuler()">Annuler</button>
        <button (click)="Confirmer()">Confirmer</button>
        
      </div>
    </div>
  </div>