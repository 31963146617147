<h2>Avec quels sites souhaitez vous rattacher {{utilisateur.prenom}} {{utilisateur.nom}}</h2>
<br><br>
<p *ngIf="this.MessageSucces !=''" class="alert alert-sucess" style="background-color: green; color: white;">
    {{MessageSucces}}</p>
<kendo-grid [data]="gridData" (dataStateChange)="dataStateChange($event)" [sortable]="true" [sort]="gridState.sort"
    [columnMenu]="true" [pageSize]="gridState.take" [skip]="gridState.skip" [height]="500" [pageable]="true"
    [filter]="gridState.filter" [filterable]="true" (edit)="RattacherUtilisateur($event)">
    <kendo-grid-column field="cabinetVeterinaire.nom" title="Cabinet"> </kendo-grid-column>
    <kendo-grid-column field="telephone" title="Téléphone"> </kendo-grid-column>
    <kendo-grid-column field="adresse" title="Adresse"></kendo-grid-column>
    <kendo-grid-column field="ville" title="Ville"></kendo-grid-column>
    <kendo-grid-column field="codePostal" title="Code Postal"></kendo-grid-column>
    <kendo-grid-column field="siret" title="N° Siret"> </kendo-grid-column>
    <kendo-grid-column title="Action">
        <ng-template kendoGridCellTemplate>
            <button themeColor="primary" kendoGridEditCommand>Rattacher</button>
        </ng-template>
    </kendo-grid-column>
</kendo-grid>
<div class="ContainerBtnFermer">
    <button kendoButton themeColor="base" class="BtnFermer" (click)="FermerPopPup()">Fermer</button>
</div>