import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../_services/GlobalService';
import { UtilisateurService } from '../../../_services/UtilisateurService';
import { PanierModel } from '../../../_models/PanierModel';
import { Produit } from '../../../_models/Produit';
import { ProduitService } from '../../../_services/ProduitService';
import { PanierService } from '../../../_services/PanierService';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ClientService } from '../../../_services/ClientService';
import { Commande } from '../../../_models/Commande';
import { Client } from '../../../_models/Client';

@Component({
  selector: 'app-header-client',
  templateUrl: './header-client.component.html',
  styleUrls: ['./header-client.component.scss']
})
export class HeaderClientComponent implements OnInit {

  public client : Client = new Client()
  private test = true
  public commande: Commande = new Commande()

  constructor(public _GlobalService: GlobalService,
    private _UtilisateurService: UtilisateurService,
    private _ProduitService: ProduitService,
    public _PanierService: PanierService,
    public _ClientService: ClientService,
    private router: Router) { }

  async ngOnInit(): Promise<void> {
    this.chargerUtilisateur()

    if (this.test) {
      this._PanierService.RefreshPanier()
      this.test = false
    }

    //Aller chercher la commande en cours du client
    this.commande = (await this._ClientService.GetCommandeClient()).objet

    //Si elle n'est pas en cours alors aller chercher la dernière
    if (this.commande == undefined) {
      this.commande = (await this._ClientService.GetLatestCommande()).objet
    }

    //Aller chercher les commandes du client
    this.client = ((await this._ClientService.GetInformations()).objet) as Client
  }

  public deconnexion() {
    this._UtilisateurService.Deconnexion()
  }

  private async chargerUtilisateur() {
    this._GlobalService.client = (await this._UtilisateurService.GetInfosUser()).objet;
    this._GlobalService.client!.PrenomNom = this._GlobalService.client?.prenom + " " + this._GlobalService.client?.nom
  }

  GoToMonCompte() {
    location.href = "/client/moncompte"
  }

  GoToPanier() {
    location.href = "/client/panier"
  }
  async GoToMemorial() {


    location.href = "/client/moncompte/animal/" + this.client.commandes[this.client.commandes.length - 1].id
  }
  GoToAccueil() {
    location.href = "/client/accueil"
  }

  async RecupQuiSommesNous() {
    this.router.navigate(['/client/quisommesnous']);
  }


}
