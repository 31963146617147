<app-header-client-mobile></app-header-client-mobile>
<div id="ContainerCategories">
    <img id="LogoBoutiqueMemorial" src="https://www.monphoenix.fr/wp-content/uploads/2024/10/la-boutique-2.png">

    <div class="burger-menu">
        <img class="burger-icon" (click)="toggleMenu()" src="assets/Utilisateur/Mobile/IconBurger.png">
        <nav id="menu2" class="menu">
            <ul>
                <li *ngFor="let categorie of nomCategories">
                    <p><span (click)="SetCategorie(categorie,true)">{{categorie}}</span></p>
                </li>
            </ul>
        </nav>
    </div>
</div>
