import { Asso_Commande_Produit } from "./Asso_Commande_Produit ";
import { ProduitCategorie } from "./CategorieProduit ";
import { OptionVariation } from "./OptionVariation";


export class Produit {
    id: number = 0;
    nom: string = "";
    uRL: string ="";
    produitCategories: ProduitCategorie[] = new Array();
    imageUrl: string = "";
    description: string = "";
    prix: number = 0;
    typeProduit: string = "";
    actif: boolean = true;
    variationsProduits: OptionVariation[] = new Array();
    variationProduit: OptionVariation = new OptionVariation();

    Quantite : number = 0
}