import { Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { SiteCabinetVeterinaire } from '../../_models/SiteCabinetVeterinaire';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { State, process } from '@progress/kendo-data-query';
import { SocieteService } from '../../_services/SocieteService';
import { GlobalService } from '../../_services/GlobalService';
import { ParcCorpsViewComponent } from '../_Views/parc-corps-view/parc-corps-view.component';
import { UtilisateurService } from '../../_services/UtilisateurService';
import { DataModelGetSites } from '../../_models/DataModelController/DataModelGetSites';

@Component({
  selector: 'app-parc-admin',
  templateUrl: './parc-admin.component.html',
  styleUrls: ['./parc-admin.component.scss']
})
export class ParcAdminComponent {

  @ViewChild('dialogActions') dialogActions!: TemplateRef<unknown>;
  public dialog !: DialogRef;

  @ViewChild("detailViewContainer", { read: ViewContainerRef })
  public detailSacContainer!: ViewContainerRef;
  public gridData!: GridDataResult;
  public gridState: State = {
    skip: 0,
    take: 50,
  };
  loading: boolean = false;
  public SitesCabinets: SiteCabinetVeterinaire[] = new Array()

  public constructor(private _SocieteService: SocieteService,
    public _GlobalService: GlobalService,
    private _dialogService: DialogService,
    private _UtilisateurService:UtilisateurService) { }

  ngOnInit(): void {
    //charge le tableau des utilisateurs
    this.ChargerSites();
  }

  public async ChargerSites() {
    this.loading = true;

    let data: DataModelGetSites = new DataModelGetSites();
    data.raccourci = false;
    data.utilisateurId = "";

    //Aller chercher les infos de l'utilisateur
    this._GlobalService.utilisateur = await (await this._UtilisateurService.GetInfosUser()).objet

    if(this._GlobalService.isAdmin()){
      this.SitesCabinets = (await this._SocieteService.GetAllSites(data)).objet;
    }else{
      data.utilisateurId = this._GlobalService.utilisateur?.id!;
      this.SitesCabinets = (await this._SocieteService.GetAllSites(data)).objet;
    }

    this.dataStateChange(this.gridState);
    this.loading = false;
  }

  public async dataStateChange(state: State) {
    this.gridState = state;
    await this.ChargerTableau();
  }
  ///charge les utilisateurs dans le tableau
  public async ChargerTableau() {
    // Init des données pour la grille
    this.gridData = process(this.SitesCabinets, this.gridState);
  }

  ConsulterSac({ dataItem }: any) {
    this.ouvreDetailSac(dataItem as SiteCabinetVeterinaire, false);
  }

  public ouvreDetailSac(site: SiteCabinetVeterinaire, nouveau: boolean) {
    this.dialog = this._dialogService.open({
      title: "Fiche du parc des corps de l'établissement " + site.CabinetVille,
      width: '90%',
      height: '90%',
      //actions: [cancelAction, saveAction],
      actions: this.dialogActions,

      appendTo: this.detailSacContainer,
      closeTitle: "Fermer",
      content: ParcCorpsViewComponent,
    });

    let DetailSac = this.dialog.content.instance;
    DetailSac.site = site;
    DetailSac.nouveau = nouveau;
  }

  public CloseView(): void {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  getNbCommandes(siteCabinet: SiteCabinetVeterinaire) {
    return siteCabinet.commandes.filter(x => x.asso_StatutCommande_Commande.length == 1).length
  }

}
