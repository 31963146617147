import { Injectable } from "@angular/core"
import { PanierModel } from "../_models/PanierModel"
import { Produit } from "../_models/Produit"
import { ProduitService } from "./ProduitService"
import { CommandeService } from "./CommandeService"
import { ClientService } from "./ClientService"
import { Commande } from "../_models/Commande"

@Injectable({
  providedIn: 'root'
})
export class PanierService {

  constructor(private _ProduitService: ProduitService, private _ClientService: ClientService) { }

  //#region Panier
  prixPanier: number = 0
  Panier: PanierModel[] = []
  private Produits: Produit[] = []

  public GetProduitspanier() {
    this.Produits.forEach(x => {
      x.variationProduitsTailles = x.variationProduitsTailles.sort((a, b) => {
        // Extraire les nombres des chaînes et les convertir en entiers
        const tailleA = parseInt(a.taille.replace(/\D/g, ''), 10);
        const tailleB = parseInt(b.taille.replace(/\D/g, ''), 10);

        return tailleA - tailleB;
      });
    });
    return this.Produits
  }

  setTaille(article: Produit, $event: any) {
    // Trouver le produit dans la liste des produits
    const produit = this.Produits.find(x => x.id == article.id);
    if (produit) {
      // Mettre à jour la taille du produit
      produit.Taille = $event;

      // Mettre à jour la taille correspondante dans le panier
      this.Panier.find(item => item.idProduit == article.id)!.taille = $event;

      // Enregistrer le panier mis à jour dans le local storage
      localStorage.setItem("Panier", JSON.stringify(this.Panier));
    }
  }
  setCouleur(article: Produit, $event: any) {
    // Trouver le produit dans la liste des produits
    const produit = this.Produits.find(x => x.id == article.id);
    if (produit) {
      // Mettre à jour la taille du produit
      produit.Couleur = $event;

      // Mettre à jour la taille correspondante dans le panier
      this.Panier.find(item => item.idProduit == article.id)!.couleur = $event;

      // Enregistrer le panier mis à jour dans le local storage
      localStorage.setItem("Panier", JSON.stringify(this.Panier));
    }
  }

  getImageproduit(article: Produit) {
    let panier: PanierModel[] = JSON.parse(localStorage.getItem("Panier")!)

    //Prendre le produit dans le panier
    let produit = panier.find(x => x.idProduit == article.id)

    if (produit) {
      if (produit.couleur != null && produit.couleur.imageUrl != "") {
        return produit.couleur.imageUrl
      } else {
        return article.imageUrl
      }
    } else {
      return ""
    }

  }

  async RefreshPanier() {
    //Aller chercher le panier dans le local storage
    this.Panier = JSON.parse(localStorage.getItem("Panier")!)

    if (this.Panier != null) {
      //Aller chercher les produits du panier
      this.Produits = (await this._ProduitService.GetProduitByIds(this.Panier.map(x => x.idProduit))).objet

      //affecter la taile aux produits
      this.Produits.forEach(x => {
        x.Taille = this.Panier.find(y => y.idProduit == x.id)!.taille
        x.Quantite = this.Panier.find(y => y.idProduit == x.id)!.quantite
      })

      this.prixPanier = this.GetTotalPanier()
    }
  }

  GetTotalPanier() {
    let prix: number = 0
    if (this.Produits == null) {
      return 0
    } else {
      this.Produits.forEach(p => {
        if (p.variationProduitsTailles.length > 0) {
          prix += p.variationProduitsTailles.find(x => x.id == p.Taille.id)!.prix * p.Quantite
        } else {
          prix += p.prix * p.Quantite
        }
      });
    }

    return prix
  }

  getTotalProduit(IdProduit: number) {
    return this.Produits.find(x => x.id == IdProduit)!.prix * this.Panier.find(x => x.idProduit == IdProduit)!.quantite
  }

  changeQuantite(event: any, IdProduit: number) {
    //Si la quantité est à zéro, on supprime le produit du panier
    if (event == 0) {
      this.Panier = this.Panier.filter(x => x.idProduit != IdProduit)
      localStorage.setItem("Panier", JSON.stringify(this.Panier))
    } else {
      this.Panier.find(x => x.idProduit == IdProduit)!.quantite = event
      localStorage.setItem("Panier", JSON.stringify(this.Panier))
    }

    this.RefreshPanier()
  }

  GetTotalQuantite() {
    if (this.Panier == null) {
      return 0
    } else {
      return this.Panier.map(x => x.quantite).reduce((a, b) => a + b, 0)
    }
  }

  calculerTVA() {
    return this.GetTotalPanier() * 0.20
  }

  ClearPanier() {
    this.Panier = []
    localStorage.removeItem("Panier")
  }

  async AjouterProduitPanier(produit: Produit) {

    let panierString = localStorage.getItem("Panier")
    let produitsPanier: Produit[] = []
    this.Panier = []

    if (panierString != null) {
      //Récupérer le panier actuel
      this.Panier = JSON.parse(localStorage.getItem("Panier")!)
      produitsPanier = (await this._ProduitService.GetProduitByIds(this.Panier.map(x => x.idProduit))).objet
    }
    
    //Si le produit est déjà dans le panier, on incrémente la quantité
    if (this.Panier.find(x => x.idProduit == produit.id)) {
      //Si le produit n'est pas une crémation
      if (produit.produitCategories.some(categorie => categorie.categorieProduit.nom != "Crémation")) {
        this.Panier.find(x => x.idProduit == produit.id)!.quantite++
      }
    } else {
      
      //Si c'est une crémation
      if(produit.id == 844 || produit.id == 845){
        //Si dans le panier iln'y a pas déja un produit de catégorie crémation
        if(!produitsPanier.some(x=>x.id == 844) && !produitsPanier.some(x=>x.id == 845)){
          this.Panier.push(new PanierModel(produit.id, 1, produit.Taille))
        }
      }else{
        this.Panier.push(new PanierModel(produit.id, 1, produit.Taille))
      }

      localStorage.setItem("Panier", JSON.stringify(this.Panier))

      this.RefreshPanier()
    }

    //#endregion

  }
}