import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataModelConnection } from '../../_models/DataModelController/DataModelConnexion';
import { DataModelPerteMDP } from '../../_models/DataModelController/DataModelPerteMdp';
import { GlobalService } from '../../_services/GlobalService';
import { UtilisateurService } from '../../_services/UtilisateurService';
import { TokenService } from '../../_services/token.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss']
})
export class LoginAdminComponent implements OnInit {
  data: DataModelConnection = new DataModelConnection();
  dataPerteMDP: DataModelPerteMDP = new DataModelPerteMDP();
  MessageSucces: string = "";
  MessageErreur: string = "";
  isMdpOublie: boolean = false
  loading: boolean = false
  is2A: boolean = false
  public Code2A1!: number | null
  public Code2A2!: number | null
  public Code2A3!: number | null
  public Code2A4!: number | null
  public Code2A5!: number | null
  public Code2A6!: number | null
  Code2A: string = "";
  messageSucces: string = "";
  public constructor(private router: Router, private _UtilisateurService: UtilisateurService,
    private globalService: GlobalService, private tokenService: TokenService,
    private DeviceDetectorService: DeviceDetectorService) { }

  @HostListener('window:keydown', ['$event'])
  async onKeyPress($event: KeyboardEvent) {
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 86 && this.globalService.utilisateur!.twoFactorEnabled) {
      let data = await navigator.clipboard.readText()
      //Le code doit faire 6 de longueur s'il ne fait pas 6 alors on ne colle pas
      if (data.length == 6) {
        //Si chaque caractère de la chaîne est un chiffre alors on colle et on submit
        if (
          !isNaN(Number(data[0])) &&
          !isNaN(Number(data[1])) &&
          !isNaN(Number(data[2])) &&
          !isNaN(Number(data[3])) &&
          !isNaN(Number(data[4])) &&
          !isNaN(Number(data[5]))
        ) {
          this.Code2A1 = Number(data[0])
          this.Code2A2 = Number(data[1])
          this.Code2A3 = Number(data[2])
          this.Code2A4 = Number(data[3])
          this.Code2A5 = Number(data[4])
          this.Code2A6 = Number(data[5])

          setTimeout(() => {
            document.getElementById("Boutton2A")!.click()
          }, 50000);
        }
      }
    }

  }

  ngOnInit(): void {
    // Get the input field
    var input = document.addEventListener('keydown', function (event) {

      if (event.key === "Enter") {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        document.getElementById("btnConnexion")!.click();
      }
    })


  }

  public async onSubmitMdpOublie() {
    this.loading = true;
    this.MessageErreur = "";
    if (this.dataPerteMDP.email) {
      let reponse = await this._UtilisateurService.MdpOublie(this.dataPerteMDP);
      if (reponse.statusCode == 200) {
        this.loading = false;
        this.MessageSucces = reponse.message
      } else {
        this.MessageErreur = reponse.messageException
        this.loading = false;
      }
    } else {
      this.loading = false;
      this.MessageErreur = "Veuillez saisir votre email"
    }
  }

  public UpdateMdpOublie() {
    this.messageSucces = ""
    this.MessageErreur = ""
    this.MessageSucces = ""
    this.isMdpOublie = !this.isMdpOublie
  }

  async FocusSuivant(NumChamp: number, evt: any) {
    let codePress = evt.keyCode
    let value = evt.key
    //Si NumChamp = 8 alors supprimer donc revenir au précdent et clear le champ et l'objet

    /*if (value == "Enter") {
      await this.onSubmit2A()
      document.getElementById("Code2A1")!.focus()
    } else {

    }*/

    //validation automatique du code
    if (NumChamp == 6) { await this.onSubmit2A() }


    if (value == "Backspace") {
      switch (NumChamp) {
        case 1:
          if (this.Code2A1 == undefined || this.Code2A1 == null) {
            document.getElementById("Code2A6")!.focus()
          } else {
            this.Code2A1 = null
          }

          break;
        case 2:
          if (this.Code2A2 == undefined || this.Code2A2 == null) {
            document.getElementById("Code2A1")!.focus()
          } else {
            this.Code2A2 = null
          }
          break;
        case 3:
          if (this.Code2A3 == undefined || this.Code2A3 == null) {
            document.getElementById("Code2A2")!.focus()
          } else {
            this.Code2A3 = null
          }
          break;
        case 4:
          if (this.Code2A4 == undefined || this.Code2A4 == null) {
            document.getElementById("Code2A3")!.focus()
          } else {
            this.Code2A4 = null
          }
          break;
        case 5:
          if (this.Code2A5 == undefined || this.Code2A5 == null) {
            document.getElementById("Code2A4")!.focus()
          } else {
            this.Code2A5 = null
          }
          break;
        case 6:
          if (this.Code2A6 == undefined || this.Code2A6 == null) {
            document.getElementById("Code2A5")!.focus()
          } else {
            this.Code2A6 = null
          }
          break
      }
    } else {
      if (isNaN(value)) {
        switch (NumChamp) {
          case 1:

            this.Code2A1 = null;
            break;
          case 2:
            this.Code2A2 = null;
            break;
          case 3:
            this.Code2A3 = null;
            break;
          case 4:
            this.Code2A4 = null;
            break;
          case 5:
            this.Code2A5 = null
            break;
          case 6:
            this.Code2A6 = null
            break
        }

      } else {
        switch (NumChamp) {
          case 1:
            document.getElementById("Code2A2")!.focus()
            break;
          case 2:
            document.getElementById("Code2A3")!.focus()
            break;
          case 3:
            document.getElementById("Code2A4")!.focus()
            break;
          case 4:
            document.getElementById("Code2A5")!.focus()
            break;
          case 5:
            document.getElementById("Code2A6")!.focus()
            break;

        }

      }
    }


  }

  //Submit la 2A
  async onSubmit2A() {

    if (this.Code2A1 == undefined || this.Code2A2 == undefined || this.Code2A3 == undefined
      || this.Code2A4 == undefined || this.Code2A5 == undefined || this.Code2A6 == undefined) {
      this.MessageErreur = "Veuillez saisir tous les champs"

    } else {
      this.Code2A = this.Code2A1.toString() + this.Code2A2.toString() + this.Code2A3.toString() + this.Code2A4.toString() + this.Code2A5.toString() + this.Code2A6.toString()

      if (this.Code2A.length != 6) {

        this.MessageErreur = "La longueur du code doit être de 6 chiffres"
      } else {
        let code = this.Code2A;
        //On demande le NumChamp de l'utilisateur
        let response = await this._UtilisateurService.ValideCode(code);
        if (response.statusCode == 200) {
          this.messageSucces = response.message

          var decodeToken = this.tokenService.getDecodedTokenStorage();

          let role: string = decodeToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

          this.loading = true;

          this.GoUrl(role)

        } else {
          this.MessageErreur = response.messageException
          //Redemander le code
        }
      }
    }

  }

  public async onSubmit() {

    this.loading = true
    this.MessageErreur = "";

    if (this.data.email && this.data.mdp) {

      // Envoyer le formulaire, par exemple, appel à un service d'authentification
      let reponse = await this._UtilisateurService.Login(this.data);
      if (reponse.statusCode == 200) {
        this.globalService.utilisateur = reponse.objet

        this.tokenService.setTokenStorage(reponse.message)

        if (this.globalService.utilisateur?.twoFactorEnabled) {
          this.loading = false;
          this.is2A = true;

        } else {

          var decodeToken = this.tokenService.getDecodedTokenStorage();
          let role: string = decodeToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
          this.loading = true;

          this.GoUrl(role)

        }

      } else {
        this.MessageErreur = reponse.messageException
        this.loading = false;
      }

    } else {
      //Afficher le message d'erreur
      this.MessageErreur = "Veuillez saisir votre email et votre mot de passe afin d'accéder à l'application"
    }
  }

  public GoUrl(role: string) {
    switch (role) {
      case "administrateur":
        this.router.navigateByUrl("/admin/commandes")
        break;
      case "vétérinaire":
        this.router.navigateByUrl("/commandes")
        break;
      default:
        // if (this.DeviceDetectorService.isMobile()) {
        this.router.navigateByUrl("/ChoixRoles")
        break;
      // } else {
      //   this.MessageErreur = "Les techniciens / chauffeurs doivent se connecter sur un téléphone mobile"
      // }
    }
  }

  public ContinuerNavigation() {

  }

}
