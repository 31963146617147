import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DataModelGetSites } from '../../../_models/DataModelController/DataModelGetSites';
import { DemandeSac } from '../../../_models/DemandeSac';
import { SiteCabinetVeterinaire } from '../../../_models/SiteCabinetVeterinaire';
import { GlobalService } from '../../../_services/GlobalService';
import { SacService } from '../../../_services/SacService';
import { SocieteService } from '../../../_services/SocieteService';

@Component({
  selector: 'app-etiquette-view',
  templateUrl: './etiquette-view.component.html',
  styleUrls: ['./etiquette-view.component.scss']
})
export class EtiquetteViewComponent implements OnInit{
  @ViewChild("detailCompteContainer", { read: ViewContainerRef })
  public detailCompteContainer!: ViewContainerRef;
  public selectedTab: number = 0;
  public verifFormInformation: boolean = true;
  @Input() public DemandeSac!: DemandeSac;
  @Input() public nouveau!: Boolean;
  @ViewChild('infoForm') infoFormSubmit!: NgForm;
  messageErreurInformation: string = "";
  loading: boolean = false;

  @ViewChild('dialogActions') dialogActions!: TemplateRef<unknown>;
  private dialog !: DialogRef;
  SitesVeterinaires: SiteCabinetVeterinaire[] = [];
  AllSitesVeterinaires: SiteCabinetVeterinaire[] = [];


  public constructor(public _SacService: SacService, public _GlobalService: GlobalService, private _dialogService: DialogService, private _SiteCabinetService: SocieteService) { }

  async ngOnInit(): Promise<void> {
    let data: DataModelGetSites = new DataModelGetSites();
    data.raccourci = false;
    data.utilisateurId = "";
    
    this.SitesVeterinaires = (await this._SiteCabinetService.GetAllSites(data)).objet;

    this.SitesVeterinaires = this.SitesVeterinaires.map(etablissement => ({
      ...etablissement,
      CabinetVille: `${etablissement.cabinetVeterinaire.nom} ${etablissement.ville}`
    }))

    this.AllSitesVeterinaires = this.SitesVeterinaires
  }

  handleFilterEtablissementveterinaire(value: any) {
    this.SitesVeterinaires = this.AllSitesVeterinaires.filter(
      etablissement =>
        etablissement.CabinetVille
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
    );
  }

  public isFormValide() {
    this.infoFormSubmit.ngSubmit.emit();

    if (!this.infoFormSubmit.invalid) {
      return true;
    } else {
      return false;
    }
  }

  public validate() {
    if (this.DemandeSac.siteCabinetVeterinaire.id == 0 ||
      this.DemandeSac.numeroSacFinal == 0 ||
      this.DemandeSac.numeroSacInitial == 0) {
      return false
    } else {
      return true;
    }
  }

  //enregistre l'utilisateur
  public async EnregistrerSac() {
    let retour = false;
    
    this.DemandeSac.siteCabinetVeterinaire.commandes = []


    this.loading = true;
    if (this.nouveau) {
      let reponse = await this._SacService.AddSac(this.DemandeSac);
      if (reponse.statusCode == 200) {
        this.verifFormInformation = true;
        retour = true;
      } else {
        this.verifFormInformation = false;
        if (reponse.messageException != undefined) {
          this.messageErreurInformation = reponse.messageException;
        }
      }
    } else {
      //utilisateur existant
      let reponse = await this._SacService.UpdateSac(this.DemandeSac);

      if (reponse.statusCode == 200) {
        this.verifFormInformation = true;
        retour = true;
      } else {
        this.verifFormInformation = false;
        this.messageErreurInformation = reponse.messageException;
      }
    }
    this.loading = false
    return retour;
  }

}
