import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataModelPerteMDP } from '../../_models/DataModelController/DataModelPerteMdp';
import { ClientService } from '../../_services/ClientService';
import { GlobalService } from '../../_services/GlobalService';
import { TokenService } from '../../_services/token.service';
import { DataModelConnectionClient } from '../../_models/DataModelController/DataModelConnectionClient';
import { Commande } from '../../_models/Commande';
import { ReponseAPI } from '../../_models/ReponseAPI';

@Component({
  selector: 'app-client-code-authentification',
  templateUrl: './client-code-authentification.component.html',
  styleUrls: ['./client-code-authentification.component.scss']
})
export class ClientCodeAuthentificationComponent implements OnInit {
  DataClient: DataModelConnectionClient = new DataModelConnectionClient()
  MessageSucces: string = "";
  MessageErreur: string = "";
  MessageSuccesRenouvellement: string = "";
  MessageErreurRenouvellement: string = "";
  isBtnNouveauLien: boolean = false;

  public constructor(private router: Router,
    private ClientSerice: ClientService, private actRoute: ActivatedRoute, private spinner: NgxSpinnerService, private globalService: GlobalService, private tokenService: TokenService,) {
    this.DataClient.lienMDP = this.actRoute.snapshot.params['CodeClient'];
    this.DataClient.code = this.actRoute.snapshot.params['Code'];
  }

  ngOnInit(): void {
    // Get the input field
    document.addEventListener('keydown', function (event) {

      if (event.key === "Enter") {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        document.getElementById("btnConnexion")!.click();
      }
    })
  }

  public async onSubmit() {

    this.spinner.show()
    this.MessageErreur = "";

    if (this.DataClient.code != "") {

      // Envoyer le formulaire
      let reponse = await this.ClientSerice.ConnectionClient(this.DataClient);

      if (reponse.statusCode == 200) {
        this.spinner.hide()

        this.globalService.client = reponse.objet

        //Set le token
        this.tokenService.setTokenStorage(reponse.message)

        //Aller chercher la commande du client
        let reponseCommande: ReponseAPI = await this.ClientSerice.GetCommandeClient()

        //Si la commande du client est terminée alors on annule sa connexion
        if (reponseCommande.statusCode == 200) {

          //Set la commande
          let Commande: Commande = reponseCommande.objet

          // //vérifier si la commande n'est pas archivé et si le statut de la commande est antérieur à 3
          if (Commande != null && !Commande.archiver && !Commande.isCremationTermine) {
            this.spinner.hide()
            localStorage.removeItem("Panier")
            this.router.navigateByUrl("/client/accueil")
          } else {

            //Checker si le client ne possède pas une commande terminée
            reponseCommande = await this.ClientSerice.GetLatestCommande()

            if (reponseCommande.objet != null) {
              //Set la commande
              let Commande: Commande = reponseCommande.objet

              let datefin = new Date(Commande.asso_StatutCommande_Commande.filter(x => x.statutCommandeId == 5)[0].date);

              // Add one month to the date
              datefin.setMonth(datefin.getMonth() + 1);

              //Si la date du jour est inférieur à la date de fin alors connexion sinon erreur
              if (new Date() < datefin) {
                this.spinner.hide()
                this.router.navigateByUrl("/client/accueil")
              } else {
                this.MessageErreur = "Votre commande est terminée. Vous ne pouvez pas vous connecter."
              }
            } else {
              this.MessageErreur = "Aucune commande n'est rattachée à ce client"
            }
          }
        } else {
          this.MessageErreur = reponseCommande.messageException
        }

      } else {
        this.isBtnNouveauLien = true;
        this.MessageErreur = reponse.messageException
      }

    } else {
      this.MessageErreur = "Veuillez saisir le code que vous avez reçu par mail."
      //Afficher le message d'erreur
    }

    this.spinner.hide()

  }

  public async DemanderNouveauLien() {
    this.spinner.show()
    this.MessageErreur = "";

    let reponse = await this.ClientSerice.DemanderNouveauLien(this.DataClient.lienMDP)

    if (reponse.statusCode == 200) {
      this.MessageSuccesRenouvellement = reponse.message
    } else {
      this.MessageErreurRenouvellement = reponse.messageException
    }

    this.spinner.hide()
  }
}
