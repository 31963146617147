import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "./GlobalService";
import { ReponseAPI } from "../_models/ReponseAPI";
import { DemandeSac } from "../_models/DemandeSac";
import { Tournee } from "../_models/Tournee";
import { DataModelAddTournee } from "../_models/DataModelController/DataModelAddTournee";

@Injectable({
    providedIn: 'root'
})
export class TourneeService {
    public constructor(private http: HttpClient, private router: Router, private _GlobalService: GlobalService) { }

    // Ajouter une surcharge pour gérer le cas où une date est fournie en tant que paramètre
    public async GetAllTournees(date?: Date): Promise<ReponseAPI> {
        if (date) {
            // Formater la date au format ISO string pour l'envoyer comme paramètre dans la requête
            const formattedDate = date.toISOString();
            return await this._GlobalService.SendGETRequest(`Tournee/Tournees?date=${formattedDate}`);
        } else {
            return await this._GlobalService.SendGETRequest("Tournee/Tournees");
        }
    }
    public async GetTourneeById(IdTournee:number): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Tournee/Tournees/" + IdTournee)
    }

    public async AddTournee(tournee: DataModelAddTournee): Promise<ReponseAPI> {
        return await this._GlobalService.SendPOSTRequest("Tournee", tournee)
    }

    public async UpdateTournee(tournee: DataModelAddTournee): Promise<ReponseAPI> {
        return await this._GlobalService.SendPUTRequest("Tournee", tournee)
    }
    public async DechargerTournee(): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Tournee/Dechargement")
    }

}