import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SiteCabinetVeterinaire } from '../../../_models/SiteCabinetVeterinaire';
import { CabinetVeterinaire } from '../../../_models/CabinetVeterinaire';
import { DataModelAddCabinet } from '../../../_models/DataModelController/DataModelAddCabinet';
import { SocieteService } from '../../../_services/SocieteService';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-ajout-site-veterinaire',
  templateUrl: './ajout-site-veterinaire.component.html',
  styleUrls: ['./ajout-site-veterinaire.component.scss']
})
export class AjoutSiteVeterinaireComponent implements OnInit {
  @Input() public cabinet!: DataModelAddCabinet;
  @Input() public site!: SiteCabinetVeterinaire;
  @Input() public nouveau!: boolean;
  @ViewChild('infoForm') infoFormSubmit!: NgForm;

  loading: boolean = false
  messageErreurInformation: string = ""

  public constructor(public CabinetVeterinaireService: SocieteService) { }

  public ngOnInit(): void {
    if (this.nouveau) {
      this.cabinet.siteCabinets.push(new SiteCabinetVeterinaire())
    } else {
      //Prendre le site du cabinet en question et le mettre en dernière position du tableau de site
      const siteRemoved = this.cabinet.siteCabinets.splice(this.cabinet.siteCabinets.findIndex(s => s.id === this.site.id), 1)[0];
      siteRemoved.horraireDebutMatin = new Date(siteRemoved.horraireDebutMatin);
      siteRemoved.horraireFinMatin = new Date(siteRemoved.horraireFinMatin);
      siteRemoved.horraireDebutApresMidi = new Date(siteRemoved.horraireDebutApresMidi);
      siteRemoved.horraireFinApresMidi = new Date(siteRemoved.horraireFinApresMidi);
      this.cabinet.siteCabinets.push(siteRemoved);
    }
  }

  public validate() {
    let site = this.cabinet.siteCabinets[this.cabinet.siteCabinets.length - 1]
    if (site.adresse && site.ville && site.codePostal && site.telephone && site.email && site.siret
      && site.horraireDebutMatin && site.horraireFinMatin && site.horraireDebutApresMidi && site.horraireFinApresMidi) {
      return true
    }
    else {
      return false
    }
  }
  
}