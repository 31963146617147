import { Component, OnInit } from '@angular/core';
import { ProduitService } from '../../_services/ProduitService';
import { Produit } from '../../_models/Produit';
import { ClientService } from '../../_services/ClientService';
import { Client } from '../../_models/Client';
import { DataModelPaiement } from '../../_models/DataModelController/DataModelPaiement';
import { ReponseAPI } from '../../_models/ReponseAPI';
import { CommandeService } from '../../_services/CommandeService';
import { PanierService } from '../../_services/PanierService';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ModelPanier } from '../../_models/ModelPanier';

@Component({
  selector: 'app-panier-client',
  templateUrl: './panier-client.component.html',
  styleUrls: ['./panier-client.component.scss']
})
export class PanierClientComponent implements OnInit {


  Produits: Produit[] = []
  Quantite: number[] = [1, 2, 3, 4, 5, 6];
  loading: boolean = false
  IsPanier: boolean = true
  Client: Client = new Client()
  messageErreur = ""
  UrlPaiement = ""
  isCGVOK: boolean = false
  isClearPanier: boolean = false
  isMobile: boolean = false

  constructor(private _DeviceService: DeviceDetectorService, private _router: ActivatedRoute, private _CommandeService: CommandeService, private _ClientService: ClientService, public _PanierService: PanierService, private _ProduitService: ProduitService) { }

  async ngOnInit(): Promise<void> {

    this.isMobile = this._DeviceService.isMobile()

    this._router.queryParams.subscribe(params => {
      if (params['clear'] === 'true') {
        this.SetCommandePayer()
        this.isClearPanier = true
        this._PanierService.ClearPanier()
      }
    });

    await this._PanierService.RefreshPanier()

    this.Produits = (await this._ProduitService.GetAllProduits()).objet

    console.log(this._PanierService.GetProduitspanier())

    //Aller chercher les détails du client connecté
    this.Client = (await this._ClientService.GetInformations()).objet
  }

  PasserCommande() {
    //Aller à l'interface de paiement de la commande
    this.IsPanier = false
  }

  async ConfirmerPaiement() {

    //Faire l'objet du paiement
    let DataModelPaiement: ModelPanier[] = []

    this._PanierService.Panier.forEach(p => {
      let data: ModelPanier = new ModelPanier()
      if (p.produit.variationProduit) {
        data = {
          idProduit: p.produit.variationProduit.id,
          isVariation: true,
          Quantite: p.quantite
        };
      } else {
        data = {
          idProduit: p.produit.id,
          isVariation: false,
          Quantite: p.quantite
        };
      }
      DataModelPaiement.push(data)
    })
    console.log(DataModelPaiement)


    //Faire la requête si les champs ne sont pas vides
    if (DataModelPaiement.length > 0) {

      //Faire la requête
      let reponse: ReponseAPI = await this._CommandeService.PasserPaiement(DataModelPaiement)

      if (reponse.statusCode == 200) {
        let a = document.createElement('a');
        a.href = reponse.objet;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        this.messageErreur = reponse.messageException
      }

    } else {
      this.messageErreur = "Veuillez remplir les champs de la carte bancaire"
    }

  }

  SetCommandePayer() {
    //Faire la requête
    this._CommandeService.SetCommandePayer()
  }

  public GetPrixPanier() {

    if (this.isCremationInPanier()) {
      return this._PanierService.GetTotalPanier()
    } else {
      return this._PanierService.GetTotalPanier()
    }

  }

  GetProduitIdByCommande(idTypeCremation: number) {

    let idProduit = 0

    //Aller chercher le produit associé à ce numéro de crémation
    switch (idTypeCremation) {
      case 1:
        //Crémation collective
        idProduit = 843
        break;
      case 2:
        //Crémation individuelle
        idProduit = 844
        break;
      case 3:
        idProduit = 845
        break;
    }

    return idProduit;
  }

  isCremationInPanier() {
    return this._PanierService.GetProduitspanier().find(x => x.nom.includes('Crémation'))
  }

  isOkPaiement() {
    return this.isCGVOK && this._PanierService.Panier.length > 0
  }

  OpenCGV() {
    let a = document.createElement('a');
    a.href = "../../assets/CGV_Phoenix.pdf";
    a.target = "_blank";
    a.click();
    document.removeChild(a)
  }

  getPrixArticle(article: Produit) {
    if (article.variationsProduits.length > 0) {
      return article.variationProduit.prix
    } else {
      return article.prix
    }
  }

  public isProduitVariations(article: Produit) {
    return article.variationsProduits.length > 0
  }
}
