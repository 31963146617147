<div [ngClass]="{
    'ContainerHeadertechnicien': role == 'Technicien', 
    'ContainerHeaderChauffeur': role == 'Chauffeur',
    'header-large': EnableScanIcon,
    'header-small': !EnableScanIcon
  }">

<div id="ContainerHaut">
  <img (click)="Retour()" src="../../../assets/TechnicienChauffeur/FlecheRetour.svg" id="FlecheRetour">
  <p id="TitreAccueil">{{Titre}}</p>
</div>

<div class="ContainerScan" *ngIf="EnableScanIcon">
  <img alt="scan" (click)="ChoisirQrCode()" src="../../../assets/TechnicienChauffeur/scan.svg" id="Scan">
</div>

<br>
</div>
