<app-header-technicien-chauffeur [Titre]="titre"></app-header-technicien-chauffeur>
<div class="Container">
    <div class="ContainerFournee">
        <button (click)="this.DechargementTournee()" id="CreationFournee">
            <span id="txtCreation">Décharger la tournée</span>
        </button>
        <br>
        <div *ngIf="this.messageSuccesInformation != ''" class="alert alert-success">
            {{messageSuccesInformation}}
        </div>
        <br>
        <p id="TextChoisirCabinet">Choisir un cabinet vétérinaire</p>
        <br>
        <app-carte-tournee [Tournees]="Tournees"></app-carte-tournee>
    </div>
   
</div>