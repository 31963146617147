<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <form #infoForm="ngForm" name="Form">
                <h2>Informations sur l'approvisionnement des étiquettes</h2>
                <br><br>
                <div *ngIf="!this.verifFormInformation" class="alert alert-danger">
                    {{messageErreurInformation}}
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <kendo-label class="k-form" text="Etablissement vétérinaires *">
                            <kendo-dropdownlist name="siteCabinetVeterinaire" textField="CabinetVille"
                                valueField="CabinetVille" [data]="SitesVeterinaires"
                                [(ngModel)]="this.DemandeSac.siteCabinetVeterinaire" required
                                [filterable]="true"
                                (filterChange)="handleFilterEtablissementveterinaire($event)">
                            </kendo-dropdownlist>
                        </kendo-label>
                    </div>
                    <div class="col-md-6">
                        <kendo-label class="k-form" text="Date d'approvisionnement *">
                            <kendo-datepicker required [(ngModel)]="this.DemandeSac.dateApprovisionnement"
                                name="dateApprovisionnement"></kendo-datepicker>
                        </kendo-label>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-6">
                        <kendo-label class="k-form" text="N° étiquette initiale *"></kendo-label>
                        <kendo-numerictextbox [format]="'n0'" name="numeroSacInitial" [min]="0" [step]="1"
                            required [(ngModel)]="this.DemandeSac.numeroSacInitial">
                        </kendo-numerictextbox>
                    </div>
                    <div class="col-md-6">
                        <kendo-label class="k-form" text="N° étiquette finale *"></kendo-label>
                        <kendo-numerictextbox [format]="'n0'" name="numeroSacFinal" [min]="0" [step]="1"
                            required [(ngModel)]="this.DemandeSac.numeroSacFinal">
                        </kendo-numerictextbox>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>
<app-loader *ngIf="loading"></app-loader>