<app-header-technicien-chauffeur Titre="Scanner une étiquette"
    [EnableScanIcon]="false"></app-header-technicien-chauffeur>
<br>
<div *ngIf="this.messageSuccesInformation != ''" class="alert alert-success">
    {{messageSuccesInformation}}
</div>

<div id="ContainerLogoScan">
    <p id="txtScan">Scan</p>
    <img src="../../../../assets/TechnicienChauffeur/LogoScan.svg" alt="LogoScan">
</div>
<br>
<div class="ContainerFournee">

    <div class="ContainerScan">
        <!-- ngx-scanner-qrcode -->
        <ngx-scanner-qrcode class="Scanner" #action="scanner" [config]="this._GlobalService.config"
            (event)="onEvent($event)"></ngx-scanner-qrcode>
        <!-- loading -->
        <p *ngIf="action.isLoading">⌛ Chargement...</p>
    </div>
</div>
<br>
<div id="AjoutEtiquetteManuellement">
    <h3>Ajouter une étiquette manuellement</h3>
    <kendo-numerictextbox #ajoutEtiquetteTextBox [min]="1" [step]="1" [decimals]="0" format="n0"
        id="AjoutEtiquetteManuelle" name="AjoutEtiquetteManuelle" [(ngModel)]="this.TxtAjoutEtiquetteManuelle">
    </kendo-numerictextbox>

    <kendo-button id="btnAjoutEtiquetteManuellent" (click)="AjouterEtiquetteManuellement()">Ajouter</kendo-button>
</div>
<br>
<audio #audioPlayer style="display: none;">
    <source src="../../../../assets/Sound/ScanQrCode.mp3" type="audio/mpeg">
</audio>
<audio #audioPlayerErreur style="display: none;">
    <source src="../../../../assets/Sound/ErrorScanQrCode.mp3" type="audio/mpeg">
</audio>
<ng-template #dialogActions>
    <button kendoButton (click)="CloseView()">Fermer</button>
    <button kendoButton themeColor="primary" (click)="SaveView()">Enregistrer</button>
</ng-template>
<ng-container #detailViewContainer></ng-container>

<kendo-dialog (close)="messageErreurInformation = ''" title="Erreur" *ngIf="messageErreurInformation !=''">
    <p style="text-align: center; color: black;">
        {{messageErreurInformation}}
    </p>
</kendo-dialog>