import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "./GlobalService";
import { ReponseAPI } from "../_models/ReponseAPI";
import { environment } from "../../environments/environment";
import { lastValueFrom } from "rxjs";
import { DataModelConnection } from "../_models/DataModelController/DataModelConnexion";
import { DataModelConnectionClient } from "../_models/DataModelController/DataModelConnectionClient";
import { Client } from "../_models/Client";
import { Commande } from "../_models/Commande";

@Injectable({
    providedIn: 'root'
})
export class ClientService {

    public constructor(private http: HttpClient, private router: Router, private _GlobalService: GlobalService) { }

    public async ObtenirLienAuth(EmailClient: string): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Client/LienAuthentication/" + EmailClient)
    }
    public async ConnectionClient(data: DataModelConnectionClient): Promise<ReponseAPI> {
        return await this._GlobalService.SendPOSTRequest("Client/Login", data)
    }

    public async GetAllClients(): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Client/Clients")
    }
    public async AjouterClient(data: Client): Promise<ReponseAPI> {
        return await this._GlobalService.SendPOSTRequest("Client", data)
    }
    public async ModifierClient(data: Client): Promise<ReponseAPI> {
        return await this._GlobalService.SendPUTRequest("Client", data)
    }
    public async GetCommandes(): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Client/Commandes")
    }
    public async GetInformations(): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Client/Informations")
    }
    public async GetCommandeClient(): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Client/Commande")
    }
    public async GetLatestCommande(): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Client/LastCommande")
    }
    public async UpdateCommandeClient(data: Commande): Promise<ReponseAPI> {
        return await this._GlobalService.SendPUTRequest("Client/Commande", data)
    }
    public async GetPageQuiSommesNous(): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Client/Pages/QuiSommesNous")
    }

}