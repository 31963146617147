<app-header-client-boutique *ngIf="!IsMobile"></app-header-client-boutique>
<app-header-client-boutique-mobile *ngIf="IsMobile"></app-header-client-boutique-mobile>

<br><br>
<div class="ContainerAnimaux">
    <img src="../../../assets/Utilisateur/LogoChienIcone.png" alt="Chien" id="Chien">
    <div id="DetailProduitCategorie">
        <p class="TxtUrnes">URNES</p>
        <p class="LogoCategorie">CHIENS</p>
        <div class="ContenuProduitsBoutique">
            <!-- Aller chercher la liste des produits via l'api -->
            <app-fiche-produit-template style="display:flex" [Produits]="produitsChien"></app-fiche-produit-template>
            <br>
        </div>
        <br>
        <br>
        <div class="ContainerBtnAutreProduit">
            <button class="TxtDecouvrezAutreProduits" (click)="GoToProduits('Urnes Chien')">Découvrez les autres
                produits
                &#10142;</button>
        </div>

    </div>
</div>
<div class="ContainerAnimaux ContainerDroite">
    <img *ngIf="this.IsMobile" src="../../../assets/Utilisateur/LogoChatIcon.png" alt="Chat" id="Chat">
    <div id="DetailProduitCategorie">
        <p class="TxtUrnes">URNES</p>
        <p class="LogoCategorie">CHATS</p>
        <div class="ContenuProduitsBoutique">
            <!-- Aller chercher la liste des produits via l'api -->
            <app-fiche-produit-template [Produits]="produitsChat"></app-fiche-produit-template>
            <br>
        </div>
        <br>
        <br>
        <div class="ContainerBtnAutreProduit">
            <button class="TxtDecouvrezAutreProduits" (click)="GoToProduits('Urnes Chat')">Découvrir les autres produits
                &#10142;</button>
        </div>
    </div>
    <img *ngIf="!this.IsMobile" src="../../../assets/Utilisateur/LogoChatIcon.png" alt="Chat" id="Chat">
</div>
<div class="ContainerAnimaux">
    <img src="../../../assets/Utilisateur/LogoNACIcone.png" alt="Chien" id="Chien">
    <div id="DetailProduitCategorie">
        <p class="TxtUrnes">URNES</p>
        <p class="LogoCategorie">NAC</p>
        <div class="ContenuProduitsBoutique">
            <!-- Aller chercher la liste des produits via l'api -->
            <div class="ContenuProduitsBoutique">
                <!-- Aller chercher la liste des produits via l'api -->
                <app-fiche-produit-template [Produits]="produitsNAC"></app-fiche-produit-template>
                <br>
            </div>
            <br>
        </div>
        <br>
        <br>
        <div class="ContainerBtnAutreProduit">
            <button class="TxtDecouvrezAutreProduits" (click)="GoToProduits('Urnes NAC')">Découvrez les autres produits
                &#10142;</button>
        </div>

    </div>
</div>
<br><br>
<div id="FooterBoutique">
    <p id="TxtBoutiquePlus">LA BOUTIQUE <b><br>DÉCOUVREZ TOUS NOS AUTRES PRODUITS FUNÉRAIRES</b></p>
    <br>
    <button id="BtnAllProduits" (click)="GoToProduits('')">VOIR TOUS LES PRODUITS &#10142;</button>
    <br><br>
</div>