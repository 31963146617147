<app-header-admin></app-header-admin>
<app-loader *ngIf="loading"></app-loader>
<div id="Cabinets">

    <div class="container">
        <div class="row">
            <div class="col-md-2">
                <app-menu-admin></app-menu-admin>
            </div>
            <div class="col-md-10 p-4">

                <h1>Société</h1>
                <br>
                <div>
                    <button kendoButton (click)="AjouterCabinet()" themeColor="primary">Ajouter une nouvelle
                        société</button>
                </div>
                <br>
                <div class="row">
                    <div class="col-12">
                        <div class="grid-wrapper">
                            <kendo-grid [data]="gridData" (dataStateChange)="dataStateChange($event)" [sortable]="true"
                                [sort]="gridState.sort" [columnMenu]="true" [pageSize]="gridState.take"
                                [skip]="gridState.skip" [height]="800" [pageable]="true" [filter]="gridState.filter"
                                [filterable]="true" (edit)="modifierCabinet($event)" [resizable]="true">
                                <kendo-grid-column field="nom" title="Nom"> </kendo-grid-column>
                                <kendo-grid-column title="Action">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <button *ngIf="dataItem.nom !='Phoenix'" themeColor="primary"
                                            kendoGridEditCommand>Modifier</button>
                                    </ng-template>
                                </kendo-grid-column>
                            </kendo-grid>

                        </div>
                    </div>
                </div>
                <br>
            </div>
        </div>
    </div>
</div>


<ng-template #dialogActions>
    <button kendoButton (click)="closeCabinet()">Fermer</button>
    <button kendoButton themeColor="primary" (click)="enregistrerCabinet()"
        *ngIf="this.dialog.content.instance.IndexTabStrip ==1"
        [disabled]="!this.dialog.content.instance.validate()">Enregistrer</button>

    <button kendoButton themeColor="primary" (click)="SuivantCabinet()"
        *ngIf="this.dialog.content.instance.IndexTabStrip ==0"
        [disabled]="!this.dialog.content.instance.IsSuivantValide()">Suivant</button>
</ng-template>
<ng-container #detailCompteContainer></ng-container>