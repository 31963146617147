import { AfterViewInit, Component, ElementRef, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgxScannerQrcodeComponent, ScannerQRCodeConfig, ScannerQRCodeResult } from 'ngx-scanner-qrcode';
import { CommandeService } from '../../../_services/CommandeService';
import { Commande } from '../../../_models/Commande';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { FicheAnimalViewComponent } from '../../_Views/fiche-animal-view/fiche-animal-view.component';
import { GlobalService } from '../../../_services/GlobalService';

@Component({
  selector: 'app-qr-code-seul',
  templateUrl: './qr-code-seul.component.html',
  styleUrls: ['./qr-code-seul.component.scss']
})
export class QrCodeSeulComponent implements AfterViewInit {
  messageErreurInformation: string = '';
  messageSuccesInformation: string = '';
  TxtAjoutEtiquetteManuelle: string = '';
  public isDialogOpen = false;

  @ViewChild('dialogActions') dialogActions!: TemplateRef<unknown>;
  private dialog!: DialogRef;

  @ViewChild("detailViewContainer", { read: ViewContainerRef })
  public detailViewContainer!: ViewContainerRef;
  loading: boolean = false;

  public constructor(private _CommandeService: CommandeService, private _DialogService: DialogService, public _GlobalService: GlobalService) { }

  @ViewChild('audioPlayer')
  audioPlayer!: ElementRef;
  @ViewChild('audioPlayerErreur')
  audioPlayerErreur!: ElementRef;
  @ViewChild('action') action!: NgxScannerQrcodeComponent;
  @ViewChild('ajoutEtiquetteTextBox', { read: ElementRef }) ajoutEtiquetteTextBox!: ElementRef;
  ngAfterViewInit(): void {
    const inputElement = this.ajoutEtiquetteTextBox.nativeElement.querySelector('input');
    if (inputElement) {
      inputElement.setAttribute('inputmode', 'numeric');
      inputElement.setAttribute('pattern', '[0-9]*');
    }
    this.DemarerScan();
  }

  ngOnDestroy(): void {
    this.StopScan();
  }

  public DemarerScan(): void {
    this.handle(this.action, 'start');
  }
  public StopScan(): void {
    this.handle(this.action, 'stop');
  }

  public OuvreFicheAnimal(commande: Commande): void {
    if (!this.isDialogOpen) {
      // Fermer le scan
      this.StopScan();

      this.dialog = this._DialogService.open({
        title: `Fiche de l'animal N° ${commande.detailCremation.sacId}`,
        width: '90%',
        height: '90%',
        actions: this.dialogActions,
        appendTo: this.detailViewContainer,
        closeTitle: "Fermer",
        content: FicheAnimalViewComponent,
      });

      let DetailClient = this.dialog.content.instance;
      DetailClient.commande = commande;

      this.isDialogOpen = true;

      this.dialog.result.subscribe(async (result) => {
        this.CloseView()
      })

    }
  }

  public async CloseView() {

    if (this.isDialogOpen) {
      this.dialog.close();
      this.isDialogOpen = false;

      // Redémarrer le scan apres la fermeture
      this.DemarerScan();
    }
  }

  public async SaveView(): Promise<void> {
    this.loading = true;
    let DetailClient = this.dialog.content.instance as FicheAnimalViewComponent;

    if (DetailClient.validate() && await DetailClient.ModifierAnimal()) {
      this.CloseView();
      this.loading = false;
    }
  }

  AjouterEtiquetteManuellement() {
    this.messageErreurInformation = '';
    if (this.TxtAjoutEtiquetteManuelle != "" && parseInt(this.TxtAjoutEtiquetteManuelle) > 0) {
      this.GetCommandeBySacId(parseInt(this.TxtAjoutEtiquetteManuelle));
    }
  }

  async GetCommandeBySacId(sacid: number) {

    // Faire la requête pour aller chercher la commande par le numéro de sac
    let reponse = await this._CommandeService.GetCommandeBySacId(sacid);

    if (reponse.statusCode == 500) {
      this.messageErreurInformation = reponse.messageException;

      //Jouer le son erreur
      this.audioPlayerErreur.nativeElement.play();

    } else {

      // Jouer le son
      this.audioPlayer.nativeElement.play();

      // Aller à la fiche de cet animal
      this.OuvreFicheAnimal(reponse.objet);
      this.TxtAjoutEtiquetteManuelle = "";
    }
  }

  //#region Qr Code
  public async onEvent(e: ScannerQRCodeResult[]): Promise<void> {
    if (!this.isDialogOpen && e && e.length > 0) {
      const newValue = parseInt(e[0].value);

      this.GetCommandeBySacId(newValue);
    }
  }

  public handle(action: any, fn: string): void {
    const playDeviceFacingBack = (devices: any[]) => {
      const device = devices.find(f => (/(back)/gi.test(f.label)));
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    }

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe(
        (r: any) => console.log(fn, r),
        (error: any) => {
          if (error instanceof DOMException && error.name === 'NotAllowedError') {
            alert('Veuillez autoriser l\'accès à la caméra pour utiliser cette fonctionnalité.');
          } else {
            alert('Une erreur est survenue lors du démarrage du scanner.');
          }
        }
      );
    } else {
      action[fn]().subscribe(
        (r: any) => console.log(fn, r),
        (error: any) => {
          console.error('Error stopping scanner:', error);
          alert('Une erreur est survenue lors de l\'arrêt du scanner.');
        }
      );
    }
  }
  //#endregion
}
