import { VariationProduitCouleur } from "./VariationProduitCouleur"
import { VariationProduitTaille } from "./VariationProduitTaille"

export class PanierModel {
    idProduit: number = 0
    taille: VariationProduitTaille = new VariationProduitTaille()
    couleur: VariationProduitCouleur | null = new VariationProduitCouleur()
    quantite: number = 0

    constructor(idProduit: number, quantite: number, taille: VariationProduitTaille, couleur: VariationProduitCouleur | null = null) {
        this.idProduit = idProduit
        this.taille = taille
        this.couleur = couleur
        this.quantite = quantite
    }
}