import { Component, ElementRef, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { State, process } from '@progress/kendo-data-query';
import { UtilisateurService } from '../../_services/UtilisateurService';
import { GlobalService } from '../../_services/GlobalService';
import { CommandeService } from '../../_services/CommandeService';
import { Commande } from '../../_models/Commande';
import { CommandeViewComponent } from '../_Views/commande-view/commande-view.component';
import { EnumStatutCommande } from '../../_models/Filter/FilterCommande';
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { CommandeArticleViewComponent } from '../_Views/commande-article-view/commande-article-view.component';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { HttpClient } from "@angular/common/http";
import { firstValueFrom, lastValueFrom } from 'rxjs';
@Component({
  selector: 'app-commandes',
  templateUrl: './commandes.component.html',
  styleUrls: ['./commandes.component.scss']
})
export class CommandesComponent {
  @ViewChild('dialogActions') dialogActions!: TemplateRef<unknown>;
  public dialog !: DialogRef;

  @ViewChild('dialogActionsCommandeArticle') dialogActionsCommandeArticle!: TemplateRef<unknown>;

  @ViewChild('content', { static: false }) content!: ElementRef;

  @ViewChild("detailCompteContainer", { read: ViewContainerRef })
  public detailCompteContainer!: ViewContainerRef;
  @ViewChild('tabstrip') ts!: TabStripComponent;
  public gridData!: GridDataResult;
  public gridState: State = {
    skip: 0,
    take: 25,
  };
  loading: boolean = false;
  afficherCommandeArchiver: boolean = false;
  Commandes: Commande[] = new Array()
  CommandesArchivees: Commande[] = new Array()
  valeursFiltre: string[] = new Array()

  public constructor(private _CommandeService: CommandeService,
    public _GlobalService: GlobalService,
    private _dialogService: DialogService,
    private http: HttpClient,
  ) { }

  async ngOnInit(): Promise<void> {
    //charge le tableau des utilisateurs
    this.listeCommandesNonArchivees();
  }

  public async listeCommandesNonArchivees() {
    this.loading = true;

    this.Commandes = (await this._CommandeService.GetAllCommandeRaccourci(EnumStatutCommande.NonArchive)).objet;

    this.ProcessCommande()
  }

  public async listeCommandesArchivees() {
    this.loading = true;

    this.Commandes = (await this._CommandeService.GetAllCommandes(EnumStatutCommande.Archive)).objet;

    this.ProcessCommande()
  }

  ProcessCommande() {
    this.Commandes = this.Commandes.map(commande => ({
      ...commande,
      PrenomNom: `${commande.client.prenom} ${commande.client.nom}`,
      dateSouscription: new Date(commande.dateSouscription),
      lblCommandeProduits: commande.asso_Commande_Produits.map(x => `${x.produit.id}-${x.produit.nom}`).join('\n'),
    }));

    //Faire les commandes de type crémation
    this.Commandes
      .filter(x => x.typeCommandeId == 2)
      .forEach(commande => {
        commande.siteCabinet.CabinetVille = commande.siteCabinet.cabinetVeterinaire.nom + " - " + commande.siteCabinet.ville,
          commande.txtAsso_StatutCommande_Commande = commande.asso_StatutCommande_Commande
            .sort((a, b) => {
              return - (a.statutCommandeId - b.statutCommandeId)
            })[0].statutCommande.nom
      }
      );

    //Faire les commandes de type article
    this.Commandes
      .filter(x => x.typeCommandeId == 1)
      .forEach(commande => {
        commande.txtAsso_StatutCommande_Commande = commande.isPayer ? "Payé" : "En attente de paiement"
      }
      );

    this.Commandes.sort((a, b) => new Date(b.dateSouscription).getTime() - new Date(a.dateSouscription).getTime())

    this.dataStateChange(this.gridState);
    this.loading = false;
  }

  getDefaultTextFilter() {
    return { operator: 'contains', value: this.valeursFiltre };
  }

  public async dataStateChange(state: State) {
    this.gridState = state;
    await this.ChargerTableau();
  }

  ///charge les utilisateurs dans le tableau
  public async ChargerTableau() {
    // Init des données pour la grille
    this.gridData = process(this.Commandes, this.gridState);
  }

  public ouvreDetailCommande(commande: Commande, nouveau: boolean, isCommandeArticle: boolean) {
    this.dialog = this._dialogService.open({
      title: "Fiche convention",
      width: '90%',
      height: '90%',
      //actions: [cancelAction, saveAction],
      actions: isCommandeArticle ? this.dialogActions : this.dialogActionsCommandeArticle,
      appendTo: this.detailCompteContainer,
      closeTitle: "Fermer",
      content: isCommandeArticle ? CommandeViewComponent : CommandeArticleViewComponent,
    });

    let DetailClient = this.dialog.content.instance;
    DetailClient.commande = commande;
    DetailClient.nouveau = nouveau;
  }

  //ajouter un nouveau client
  public AjouterCommande() {
    this.ouvreDetailCommande(new Commande(), true, true);
  }

  //ferme la boite de dialogue
  public closeClient(): void {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  async modifieCompte(dataItem: Commande) {
    this.loading = true;

    //Aller chercher toutes les informations de la commande

    if (dataItem.typeCommandeId == 2) {
      dataItem = await (await this._CommandeService.GetCommandeBySacId(dataItem.detailCremation.sacId)).objet
    } else {
      let IdCommande: number[] = [dataItem.id]
      dataItem = await (await this._CommandeService.GetCommandeByIds(IdCommande)).objet[0]
    }

    this.loading = false;
    dataItem.typeCommandeId == 1 ? this.ouvreDetailCommande(dataItem, false, false) : this.ouvreDetailCommande(dataItem, false, true)
  }

  SuivantCommande() {
    (this.dialog.content.instance as CommandeViewComponent).SuivantCommande();
  }

  public async enregistreClient() {

    let DetailClient: CommandeViewComponent = this.dialog.content.instance;

    if (DetailClient.validate() && await DetailClient.EnregistrerCommande()) {

      this.loading = true;

      await this.listeCommandesNonArchivees();

      this.closeClient();

      this.loading = false;
    }
  }

  AfficherCommandeArchiver(): void {
    this.afficherCommandeArchiver = !this.afficherCommandeArchiver

    if (this.afficherCommandeArchiver) {
      //Aller chercher les commandes archivées
      this.listeCommandesArchivees();
    } else {
      this.listeCommandesNonArchivees();
    }

    //afficher les commandes qui ne sont pas archivées
    this.dataStateChange(this.gridState);

  }

  async ImprimerFicheConvention(dataItem: any) {
    this.loading = true;

    let commande = dataItem as Commande;

    // Aller chercher en détail les informations de la commande
    commande = (await this._CommandeService.GetCommandeBySacId(commande.detailCremation.sacId)).objet;

    // Charger le fichier HTML
    let html = await lastValueFrom(this.http.get('assets/FicheConvention/Convention.html', { responseType: 'text' }));

    //Remplacer les données du client dans le fichier HTML
    html = this.ReplaceInfosCommande(html, commande);

    // Charger le fichier CSS
    const css = await lastValueFrom(this.http.get('assets/FicheConvention/style.css', { responseType: 'text' }));

    // Appeler la méthode pour générer le PDF
    await this.downloadFile(html!, css!);

    this.loading = false;
  }

  ReplaceInfosCommande(html: string, commande: Commande): string {
    //Infos Client
    html = html.replace("{NomClient}", commande.client.prenom + " " + commande.client.nom)
    html = html.replace("{NomClientSignature}", commande.client.prenom + " " + commande.client.nom)
    html = html.replace("{NomClientDernierePage}", commande.client.prenom + " " + commande.client.nom)
    html = html.replace("{AdresseClient}", commande.client.adresse)
    html = html.replace("{CodePostalClient}", commande.client.codePostal)
    html = html.replace("{VilleClient}", commande.client.ville)
    html = html.replace("{TelephoneClient}", commande.client.telephone)
    html = html.replace("{EmailClient}", commande.client.email)

    //Formater la date de décès
    const dateDeces = new Date(commande.animal.dateDeces).toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });

    //Infos animal
    html = html.replace("{NomAnimal}", commande.animal.nom)
    html = html.replace("{CategorieAnimal}", commande.animal.categorieAnimal.nom)
    html = html.replace("{DateDecesAnimal}", dateDeces)
    html = html.replace("{NumeroEtiquette}", commande.detailCremation.sacId.toString())

    //Infos de la convention
    const DateDuJour = new Date().toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    html = html.replace("{Date}", DateDuJour) //Date sur la page 1
    html = html.replace("{DateDuJour}", DateDuJour) //Date sur la dernière page

    const dateCommande = new Date(commande.dateSouscription).toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    html = html.replace("{DateCommande}", dateCommande) //Date sur la dernière page

    return html;
  }


  private async downloadFile(html: string, css: string) {

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
      hotfixes: [] // Fixes pour les bugs connus
    });

    const element = document.createElement('div');
    element.innerHTML = html;
    element.style.cssText = `
      margin: 0;
      padding: 0;
      font-family: Arial, sans-serif;
      width: 210mm;
      height: 297mm;
      padding: 10mm;
      font-size: 9px;
    `;

    const style = document.createElement('style');
    style.innerHTML = css;

    document.head.appendChild(style);
    document.body.appendChild(element);

    // Récupérer toutes les pages (divs)
    const pages = element.querySelectorAll('.page');

    for (let i = 0; i < pages.length; i++) {
      const canvas = await html2canvas(pages[i] as HTMLElement, {
        scale: 2,
        useCORS: true
      });

      const imgData = canvas.toDataURL('image/png');

      // Ajouter des marges définies
      const marginLeft = 10; // Marge gauche de 10mm
      const marginTop = 10; // Marge en haut de 10mm

      // Calculer la taille de l'image avec les marges
      const pageWidth = pdf.internal.pageSize.getWidth();

      // Réduire la largeur de l'image en tenant compte des marges
      const imgWidth = pageWidth - 2 * marginLeft; // Largeur de l'image ajustée pour tenir compte des marges
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Hauteur ajustée proportionnellement

      // Ajouter l'image au PDF avec les marges
      pdf.addImage(imgData, 'PNG', marginLeft, marginTop, imgWidth, imgHeight);

      // Ajouter une nouvelle page sauf pour la dernière page
      if (i < pages.length - 1) {
        pdf.addPage();
      }
    }

    pdf.save('FicheConvention.pdf');

    document.body.removeChild(element);
    document.head.removeChild(style);
  }

  RefreshConvention() {
    this.listeCommandesNonArchivees()
  }

}
