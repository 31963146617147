import { Injectable } from '@angular/core';
import { ComponentRef, ApplicationRef, Injector, ComponentFactoryResolver } from '@angular/core';
import { ConfirmerSuppressionTourneeViewComponent } from '../Admin/_Views/confirmer-suppression-tournee-view/confirmer-suppression-tournee-view.component';



@Injectable({
  providedIn: 'root'
})
export class PopupServiceConfirmerSuppressionTournee {
  private popupRef: ComponentRef<ConfirmerSuppressionTourneeViewComponent> | null = null;

  constructor(
    private appRef: ApplicationRef,
    private injector: Injector,
    private resolver: ComponentFactoryResolver
  ) {}

  showPopup(annuler: () => void, confirmer: () => void) {
    if (this.popupRef) return; // Empêche d’afficher plusieurs popups

    const factory = this.resolver.resolveComponentFactory(ConfirmerSuppressionTourneeViewComponent);
    this.popupRef = factory.create(this.injector);

    this.popupRef.instance.annuler.subscribe(() => {
      annuler();
      this.closePopup();
    });

    this.popupRef.instance.confirmer.subscribe(() => {
      confirmer();
      this.closePopup();
    });

    this.appRef.attachView(this.popupRef.hostView);
    document.body.appendChild(this.popupRef.location.nativeElement);
  }

  closePopup() {
    if (this.popupRef) {
      this.appRef.detachView(this.popupRef.hostView);
      this.popupRef.destroy();
      this.popupRef = null;
    }
  }
}
