<app-header-admin></app-header-admin>
<app-loader *ngIf="loading"></app-loader>
<div id="habilitations">

    <div class="container">
        <div class="row">
            <div class="col-md-2">
                <app-menu-admin></app-menu-admin>
            </div>
            <div class="col-md-10 p-4">

                <h1>Conventions de crémations</h1>
                <br>
                <div class="ContainerCommande">
                    <div>
                        <button kendoButton (click)="AjouterCommande()" themeColor="primary">Ajouter une nouvelle
                            convention
                        </button>
                        <br>
                        <br>
                         <button kendoButton (click)="RefreshConvention()" themeColor="warning">Rafraichir les
                            conventions
                        </button> 
                    </div>
                    <div>
                        <kendo-label class="k-checkbox-label" [for]="archiver"
                            text="Afficher les conventions archivées"></kendo-label>
                        <input type="checkbox" (click)="AfficherCommandeArchiver()" name="afficherCommandeArchiver"
                            [checked]="this.afficherCommandeArchiver" #archiver kendoCheckBox />
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12">
                        <div class="grid-wrapper">
                            <kendo-grid [data]="gridData" [sortable]="true" [sort]="gridState.sort"
                                [pageSize]="gridState.take" [skip]="gridState.skip" [height]="800" [pageable]="true"
                                [filter]="gridState.filter" [filterable]="true" [resizable]="true"
                                (dataStateChange)="dataStateChange($event)">
                                <kendo-grid-column id="txtStatut" field="numero" title="Numéro">
                                </kendo-grid-column>
                                <kendo-grid-column filter="date" id="txtStatut" field="dateCreation"
                                    title="Date de création">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        {{dataItem.dateCreation | date:'dd/MM/yyyy'}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="emailProprietaire"
                                    title="Email du propriétaire"></kendo-grid-column>
                                <kendo-grid-column id="txtStatut" field="nomProprietaire"
                                    title="Propriétaire"></kendo-grid-column>
                                <kendo-grid-column id="txtStatut" field="nomCabinet"
                                    title="Société"></kendo-grid-column>
                                <kendo-grid-column id="txtStatut" field="typeConvention" title="Type de convention">
                                </kendo-grid-column>
                                <kendo-grid-column id="txtStatut" field="statut" title="Statut">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <p [ngClass]="{'text-green': dataItem.statut === 'Incinéré'}">
                                            {{dataItem.statut}}
                                        </p>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="detailConvention" title="Détail convention"
                                    [filterable]="true">

                                </kendo-grid-column>
                                <kendo-grid-column title="Action">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <img src="../../../assets/TechnicienChauffeur/editer.png" alt=""
                                            (click)="modifieCompte(dataItem)" style="cursor: pointer;">
                                        <img *ngIf="dataItem.typeCommandeId == 2"
                                            src="../../../assets/TechnicienChauffeur/fichier-pdf.png" alt=""
                                            (click)="ImprimerFicheConvention(dataItem)" style="cursor: pointer;">
                                        <img *ngIf="dataItem.typeCommandeId == 2 && this._GlobalService.isAdmin()"
                                            src="../../../assets/Admin/Trash.svg" alt=""
                                            (click)="SupprimerCommande(dataItem)" style="cursor: pointer;">
                                    </ng-template>
                                </kendo-grid-column>

                                <!-- <ng-template kendoPagerTemplate let-totalPages="totalPages"
                                    let-currentPage="currentPage">
                                    <kendo-pager-input></kendo-pager-input>
                                    <kendo-pager-info></kendo-pager-info>
                                </ng-template> -->
                            </kendo-grid>
                        </div>
                    </div>
                </div>
                <br>
            </div>
        </div>
    </div>
</div>

<!-- Fiche commande animal -->
<ng-template #dialogActions>
    <button kendoButton (click)="closeClient()">Fermer</button>
    <button kendoButton themeColor="primary" (click)="enregistreClient()"
        *ngIf="this.dialog.content.instance.IndexTabStrip ==2"
        [disabled]="!this.dialog.content.instance.validate()">Enregistrer</button>
    <button kendoButton themeColor="primary" (click)="SuivantCommande()"
        *ngIf="this.dialog.content.instance.IndexTabStrip ==0"
        [disabled]="!this.dialog.content.instance.IsSuivantValide()">Suivant</button>
    <button kendoButton themeColor="primary" (click)="SuivantCommande()"
        *ngIf="this.dialog.content.instance.IndexTabStrip ==1"
        [disabled]="!this.dialog.content.instance.IsSuivantValideCremation()">Suivant</button>
</ng-template>

<!-- Fiche commande article -->
<ng-template #dialogActionsCommandeArticle>
    <button kendoButton (click)="closeClient()">Fermer</button>
    <button kendoButton themeColor="primary" (click)="enregistreClient()">Enregistrer</button>

</ng-template>
<ng-container #detailCompteContainer></ng-container>