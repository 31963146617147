import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { DataModelCreateAccount } from '../../../_models/DataModelController/DataModelCreateAccount';
import { Role } from '../../../_models/Role';
import { Utilisateur } from '../../../_models/Utilisateur';
import { UtilisateurService } from '../../../_services/UtilisateurService';
import { GlobalService } from '../../../_services/GlobalService';
import { SiteCabinetVeterinaire } from '../../../_models/SiteCabinetVeterinaire';
import { RattacherUtilisateurSiteComponent } from '../rattacher-utilisateur-site/rattacher-utilisateur-site.component';
import { SocieteService } from '../../../_services/SocieteService';

@Component({
  selector: 'app-compte-view',
  templateUrl: './compte-view.component.html',
  styleUrls: ['./compte-view.component.scss']
})
export class CompteViewComponent implements OnInit {
  @ViewChild("detailCompteContainer", { read: ViewContainerRef })
  public detailCompteContainer!: ViewContainerRef;
  public selectedTab: number = 0;
  public verifFormInformation: boolean = true;
  @Input() public client!: Utilisateur;
  @Input() public nouveau!: Boolean;
  @ViewChild('infoForm') infoFormSubmit!: NgForm;
  public utilisateur: DataModelCreateAccount = new DataModelCreateAccount()
  public Roles: Role[] = []
  messageErreurInformation: string = "";
  loading: boolean = false;

  @ViewChild('dialogActions') dialogActions!: TemplateRef<unknown>;
  private dialog !: DialogRef;

  MessageSuccesDetacherUtilisateur: string = "";
  MessageErreurDetacherUtilisateur: string = "";

  //#region Grid
  public gridData!: GridDataResult;
  public gridState: State = {
    skip: 0,
    take: 25,
  };
  public async dataStateChange(state: State) {
    this.gridState = state;
    await this.ChargerTableau();
  }
  ///charge les utilisateurs dans le tableau
  public async ChargerTableau() {
    // Init des données pour la grille
    this.gridData = process(this.utilisateur.sitesCabinetVeterinaire, this.gridState);
  }

  //#endregion


  public constructor(public UtilisateurService: UtilisateurService, public _GlobalService: GlobalService, private _dialogService: DialogService, private _CabinetService: SocieteService) { }

  async ngOnInit(): Promise<void> {
    this.Roles = (await this.UtilisateurService.GetAllRoles()).objet

    this.Roles = this.Roles.filter(x => x.name != "executable")

    //charge l'utilisateur
    this.chargeUtilisateur();

    this.dataStateChange(this.gridState);

  }

  //charge l'utilisateur
  public async chargeUtilisateur() {

    if (!this.nouveau) {
      this.utilisateur.id = this.client.id;
      this.utilisateur.nom = this.client.nom;
      this.utilisateur.prenom = this.client.prenom;
      this.utilisateur.email = this.client.email;
      this.utilisateur.telephone = this.client.phoneNumber;
      this.utilisateur.commentaire = this.client.commentaire;
      this.utilisateur.adresse = this.client.adresse;
      this.utilisateur.ville = this.client.ville;
      this.utilisateur.codePostal = this.client.codePostal;
      this.utilisateur.securiteSociale = this.client.securiteSociale;
      this.utilisateur.role = this.Roles.filter(x => x.name == this.client.role)[0];
      this.utilisateur.sitesCabinetVeterinaire = this.client.siteCabinetVeterinaires
      this.utilisateur.activer = this.client.activer;
      if (this.client.dateNaissance != null) this.utilisateur.dateNaissance = new Date(this.client.dateNaissance);
      if (this.client.dateEmbauche != null) this.utilisateur.dateEmbauche = new Date(this.client.dateEmbauche);
    } else {
      this.utilisateur.dateEmbauche = new Date();
    }
  }

  public isFormValide() {
    this.infoFormSubmit.ngSubmit.emit();

    if (!this.infoFormSubmit.invalid) {
      return true;
    } else {
      return false;
    }
  }

  public validate() {
    this.infoFormSubmit.ngSubmit.emit();
    this.infoFormSubmit.control.markAllAsTouched();
    if (!this.infoFormSubmit.invalid && this.utilisateur.role != undefined) {
      return true
    }
    else {
      return false;
    }
  }

  //enregistre l'utilisateur
  public async enregistreUtilisateur() {
    let retour = false;
    //si nouvel utilisateur

    this.loading = true;
    if (this.nouveau) {
      let reponse = await this.UtilisateurService.AjouterUtilisteur(this.utilisateur);
      if (reponse.statusCode == 200) {
        this.verifFormInformation = true;
        retour = true;
      } else {
        this.verifFormInformation = false;
        if (reponse.messageException != undefined) {
          this.messageErreurInformation = reponse.messageException;
        }

      }
      this.loading = false

    } else {
      //utilisateur existant
      let reponse = await this.UtilisateurService.ModifierUtilisateur(this.utilisateur, this.client.id);

      if (reponse.statusCode == 200) {
        this.verifFormInformation = true;
        retour = true;
      } else {
        this.verifFormInformation = false;
        if (reponse.messageException != undefined) {
          this.messageErreurInformation = reponse.messageException;
        }
      }
    }
    return retour;
  }

  public async onSelect(e: any) {
    this.selectedTab = e.index;
    this._GlobalService.changeOngletClient(this.selectedTab);
  }



  public RattacherSiteVeterinaire() {
    this.ouvreDetailSite(this.utilisateur, false);
  }

  public closeCabinet(): void {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  //Partie Rattachement des sites
  public ouvreDetailSite(data: DataModelCreateAccount, nouveau: boolean) {
    this.dialog = this._dialogService.open({
      title: "Fiche du site",
      width: '90%',
      height: '90%',
      //actions: [cancelAction, saveAction],


      appendTo: this.detailCompteContainer,
      closeTitle: "Fermer",
      content: RattacherUtilisateurSiteComponent,
    });

    let DetailSite = this.dialog.content.instance;
    DetailSite.utilisateur = data;
    DetailSite.nouveau = nouveau;
    this.dialog.result.subscribe(() => {
      this.dataStateChange(this.gridState)
    })
  }

  DetacherUtilisateurSite(site: SiteCabinetVeterinaire) {
    const dialogConfirmCancel = this._dialogService.open({
      title: `Supprimer la liaison entre l'utilisateur et le site `,
      content: `Voulez-vous détacher l'utilisateur ${this.utilisateur.prenom} ${this.utilisateur.nom} du site ${site.cabinetVeterinaire.nom}-${site.ville} ?`,
      actions: [
        { text: 'Annuler', themeColor: 'error' },
        { text: 'Valider', themeColor: 'primary' },
      ]
    });

    dialogConfirmCancel.result.subscribe(async (result) => {
      if (!(result instanceof DialogCloseResult)) {
        if (result.text == "Valider") {
          let reponse = await this._CabinetService.DetacherutilisateurSite(this.utilisateur.id, site.id)
          if (reponse.statusCode == 200) {
            const index = this.utilisateur.sitesCabinetVeterinaire.indexOf(site, 0);
            if (index > -1) {
              this.utilisateur.sitesCabinetVeterinaire.splice(index, 1);
              this.dataStateChange(this.gridState);
            }
          } else {

          }
        } else {
          dialogConfirmCancel.close();
        }
      }
    })
  }

  public isTechnicienChauffeur(): boolean {
    return this.utilisateur.role.name == 'technicienchauffeur'
  }
}

