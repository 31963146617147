<div id="header">
    <img src="https://www.monphoenix.fr/wp-content/uploads/2024/11/logo-horizontal.png" (click)="GoToAccueil()"
        alt="Logo" id="LogoPhoenix">
    <br>

    <div class="burger-menu">
        <img class="burger-icon" (click)="toggleMenu()" src="assets/Utilisateur/Mobile/IconBurger.png">
        <nav id="menu" class="menu">
            <ul>
                <li><a target="_blank" href="https://www.monphoenix.fr/qui-sommes-nous/">Qui sommes nous</a></li>
                <li><a href="/client/boutique">La boutique</a></li>
                <li><a href="/client/panier">Panier</a></li>

            </ul>
        </nav>
    </div>

</div>