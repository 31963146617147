import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TourneeEtablissement } from '../../../_models/TourneeEtablissement';
import { GlobalService } from '../../../_services/GlobalService';
import { Router } from '@angular/router';
import { Tournee } from '../../../_models/Tournee';
import { DataModelGetSacAffecter } from '../../../_models/DataModelController/DataModelGetSacAffecter';
import { SacService } from '../../../_services/SacService';
import { ReponseSacTypeSac } from '../../../_models/DataModelController/ReponseSacTypeSac';
import { ThisReceiver } from '@angular/compiler';
import { SiteCabinetVeterinaire } from '../../../_models/SiteCabinetVeterinaire';
import { TourneeService } from '../../../_services/TourneeService';

@Component({
  selector: 'app-carte-tournee',
  templateUrl: './carte-tournee.component.html',
  styleUrls: ['./carte-tournee.component.scss']
})
export class CarteTourneeComponent implements OnInit, OnChanges {
  @Input() Tournees: TourneeEtablissement[] = new Array();

  reponseSacTypeSac: ReponseSacTypeSac[] = []
  data: DataModelGetSacAffecter = new DataModelGetSacAffecter()

  constructor(private _SacService: SacService, private _GlobalService: GlobalService, private _TourneeService: TourneeService, private _router: Router) { }


  async ngOnChanges(changes: SimpleChanges): Promise<void> {

    // Récupérer et parser l'objet stocké dans localStorage
    let tournee: Tournee = JSON.parse(localStorage.getItem("tournee") || '{}');

    await this.UpdateTourneeCommandes(tournee.id)
  }


  async ngOnInit() {

    //La date de début est égal à la date actuelle -1 mois
    this.data.dateDebut = new Date(2022, 0, 1)
    this.data.dateFin = new Date()

    let reponse: ReponseSacTypeSac[] = (await this._SacService.GetSacAffecter(this.data)).objet

    // Récupérer et parser l'objet stocké dans localStorage
    let tournee: Tournee = JSON.parse(localStorage.getItem("tournee") || '{}');

    // Vérifier si l'objet est valide avant d'effectuer l'appel API
    if (tournee && tournee.id) {
      await this.UpdateTourneeCommandes(tournee.id)
    }

    //Filtrer la reponse en ne prenant que les etablissements présents dans la touenee
    this.reponseSacTypeSac = reponse.filter(x => this.Tournees.some(tournee => tournee.siteCabinetVeterinairetId == x.site.id))
  }

  async UpdateTourneeCommandes(tourneeId: number) {
    this.Tournees = ((await this._TourneeService.GetTourneeById(tourneeId)).objet as Tournee[])[0].tourneeEtablissements;
    console.log(this.Tournees)
  }

  DetailTourneeCabinet(tournee: TourneeEtablissement) {
    this._GlobalService.tournee = tournee;
    this._router.navigateByUrl("Chauffeur/Tournee/Etablissement/" + tournee.siteCabinetVeterinairetId)
  }

  isTourneeValid(tournee: TourneeEtablissement): boolean {
    return tournee.siteCabinetVeterinaire.commandes.some(commande =>
      commande.asso_StatutCommande_Commande.some(x => x.statutCommandeId !== 1)
    );
  }

  getAnimauxRecupere(tournee: TourneeEtablissement): number {
    //Prendre les commandes non finies et pas archivées
    let commandes = tournee.siteCabinetVeterinaire.commandes.filter(commande => !commande.archiver && !commande.isCremationTermine)

    //Dans ces commandes, prendre seulement les commandes qui n'ont qu'une ligne dans assoStatutCommande et que cette ligne à le statut 1
    let nb = commandes.filter(commande => commande.asso_StatutCommande_Commande.length == 1 && commande.asso_StatutCommande_Commande[0].statutCommandeId == 1).length

    return nb
  }

  getNbCommandes(tournee: TourneeEtablissement) {
    return tournee.siteCabinetVeterinaire.commandes.length
  }

  getNbSacsConsomes(tournee: TourneeEtablissement, taille: string): number {

    let reponse = this.reponseSacTypeSac.filter(x => x.site.id == tournee.siteCabinetVeterinairetId)[0]

    if (reponse != null) {

      switch (taille) {
        case "XS":
          return reponse.nbTailleXS
        case "S":
          return reponse.nbTailleS
        case "M":
          return reponse.nbTailleM
        case "L":
          return reponse.nbTailleL
        case "XL":
          return reponse.nbTailleXL
        default: return 0
      }
    } else {
      return 0
    }

  }

}