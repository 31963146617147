import { LOCALE_ID, NgModule } from '@angular/core';
import "@progress/kendo-angular-intl/locales/fr/all";

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr, 'fr');

import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LabelModule } from '@progress/kendo-angular-label';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxScannerQrcodeModule, LOAD_WASM } from 'ngx-scanner-qrcode';
import { LoginComponent } from './Client/login/login.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { HttpClientModule } from '@angular/common/http';
import { GlobalService } from './_services/GlobalService';
import { TokenService } from './_services/token.service';
import { httpInterceptorProviders } from './_helpers/index.interceptor';
import { JwtModule } from '@auth0/angular-jwt';
import { NgxSpinnerModule } from "ngx-spinner";
import { HeaderComponent } from './Global/header/header.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ComptesAdminComponent } from './Admin/comptes-admin/comptes-admin.component';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { HeaderAdminComponent } from './Admin/header-admin/header-admin.component';
import { MenuAdminComponent } from './Admin/menu-admin/menu-admin.component';
import { PasswordComponent } from './Global/password/password.component';
import { LoginAdminComponent } from './Admin/login-admin/login-admin.component';
import { ClientCodeAuthentificationComponent } from './Client/client-code-authentification/client-code-authentification.component';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { LoaderComponent } from './Global/loader/loader.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { MessageService } from '@progress/kendo-angular-l10n';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { SocieteAdminComponent } from './Admin/societe-admin/societe-admin.component';
import { SocieteViewComponent } from './Admin/_Views/societe-view/societe-view.component';
import { AjoutSiteVeterinaireComponent } from './Admin/_Views/ajout-site-veterinaire/ajout-site-veterinaire.component';
import { RattacherUtilisateurSiteComponent } from './Admin/_Views/rattacher-utilisateur-site/rattacher-utilisateur-site.component';
import { CommandesComponent } from './Admin/commandes/commandes.component';
import { CompteViewComponent } from './Admin/_Views/compte-view/compte-view.component';
import { TradTelerikService } from './_models/tradTelerik';
import { ClientsAdminComponent } from './Admin/clients-admin/clients-admin.component';
import { ClientViewComponent } from './Admin/_Views/client-view/client-view.component';
import { SacsAdminComponent } from './Admin/sacs-admin/sacs-admin.component';
import { ChoixRolesComponent } from './TechnicienChauffeur/choix-roles/choix-roles.component';
import { TechnienAccueilComponent } from './TechnicienChauffeur/Technicien/technien-accueil/technien-accueil.component';
import { ChauffeurAccueilComponent } from './TechnicienChauffeur/Chauffeur/chauffeur-accueil/chauffeur-accueil.component';
import { HeaderTechnicienChauffeurComponent } from './TechnicienChauffeur/header-technicien-chauffeur/header-technicien-chauffeur.component';
import { TechnicienFourneeComponent } from './TechnicienChauffeur/Technicien/technicien-fournee/technicien-fournee.component';
import { CarteAnimalComponent } from './TechnicienChauffeur/Carte/carte-animal/carte-animal.component';
import { CommandeViewComponent } from './Admin/_Views/commande-view/commande-view.component';
import { TourneeAdminComponent } from './Admin/tournee-admin/tournee-admin.component';
import { TourneeViewComponent } from './Admin/_Views/tournee-view/tournee-view.component';
import { CarteTourneeComponent } from './TechnicienChauffeur/Carte/carte-tournee/carte-tournee.component';
import { DetailCabinetTourneeComponent } from './TechnicienChauffeur/Chauffeur/detail-cabinet-tournee/detail-cabinet-tournee.component';
import { CarteCabinetCommandesComponent } from './TechnicienChauffeur/Carte/carte-cabinet-commandes/carte-cabinet-commandes.component';
import { ParcAdminComponent } from './Admin/parc-admin/parc-admin.component';
import { ParcCorpsViewComponent } from './Admin/_Views/parc-corps-view/parc-corps-view.component';
import { FicheAnimalViewComponent } from './TechnicienChauffeur/_Views/fiche-animal-view/fiche-animal-view.component';
import { QrCodeMultiComponent } from './TechnicienChauffeur/_QrCode/qr-code-multi/qr-code-multi.component';
import { QrCodeSeulComponent } from './TechnicienChauffeur/_QrCode/qr-code-seul/qr-code-seul.component';
import { ParcPhoenixAdminComponent } from './Admin/parc-phoenix-admin/parc-phoenix-admin.component';
import { FactureAdminComponent } from './Admin/facture-admin/facture-admin.component';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { MonCompteAdminComponent } from './Admin/mon-compte-admin/mon-compte-admin.component';
import { CommandeArticleViewComponent } from './Admin/_Views/commande-article-view/commande-article-view.component';
import { IconsModule } from '@progress/kendo-angular-icons';
import { AccueilClientComponent } from './Client/accueil-client/accueil-client.component';
import { HeaderClientComponent } from './Client/HeaderClient/header-client/header-client.component';
import { MenuClientComponent } from './Client/menu-client/menu-client.component';
import { CommandeClientViewComponent } from './Client/_Views/commande-client-view/commande-client-view.component';
import { EtiquetteAdminComponent } from './Admin/etiquette-admin/etiquette-admin.component';
import { EtiquetteViewComponent } from './Admin/_Views/etiquette-view/etiquette-view.component';
import { BoutiqueClientComponent } from './Client/boutique-client/boutique-client.component';
import { FicheProduitClientComponent } from './Client/fiche-produit-client/fiche-produit-client.component';
import { PanierClientComponent } from './Client/panier-client/panier-client.component';
import { FooterClientComponent } from './Client/FootersClient/footer-client/footer-client.component';
import { MonCompteMemorialComponent } from './Client/Memorial/mon-compte-memorial/mon-compte-memorial.component';
import { FicheAnimalProprietaireComponent } from './Client/Memorial/fiche-animal-proprietaire/fiche-animal-proprietaire.component';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { BoutiqueClientCategorieComponent } from './Client/boutique-client-categorie/boutique-client-categorie.component';
import { FicheProduitTemplateComponent } from './Client/Template/fiche-produit-template/fiche-produit-template.component';
import { HeaderClientBoutiqueComponent } from './Client/HeaderClient/header-client-boutique/header-client-boutique.component';
import { QuiSommesNousComponent } from './Client/qui-sommes-nous/qui-sommes-nous.component';
import { HeaderClientMobileComponent } from './Client/HeaderClient/header-client-mobile/header-client-mobile.component';
import { FooterClientMobileComponent } from './Client/FootersClient/footer-client-mobile/footer-client-mobile.component';
import { HeaderClientBoutiqueMobileComponent } from './Client/HeaderClient/header-client-boutique-mobile/header-client-boutique-mobile.component';

export function tokenGetter() {
  return localStorage.getItem("token");
}

// Necessary to solve the problem of losing internet connection
LOAD_WASM().subscribe();

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    ComptesAdminComponent,
    HeaderAdminComponent,
    MenuAdminComponent,
    PasswordComponent,
    LoginAdminComponent,
    ClientCodeAuthentificationComponent,
    LoaderComponent,
    SocieteAdminComponent,
    SocieteViewComponent,
    AjoutSiteVeterinaireComponent,
    RattacherUtilisateurSiteComponent,
    CommandesComponent,
    CompteViewComponent,
    ClientsAdminComponent,
    ClientViewComponent,
    SacsAdminComponent,
    ChoixRolesComponent,
    TechnienAccueilComponent,
    ChauffeurAccueilComponent,
    HeaderTechnicienChauffeurComponent,
    TechnicienFourneeComponent,
    CarteAnimalComponent,
    CommandeViewComponent,
    TourneeAdminComponent,
    TourneeViewComponent,
    CarteTourneeComponent,
    DetailCabinetTourneeComponent,
    CarteCabinetCommandesComponent,
    ParcAdminComponent,
    ParcCorpsViewComponent,
    FicheAnimalViewComponent,
    QrCodeMultiComponent,
    QrCodeSeulComponent,
    ParcPhoenixAdminComponent,
    FactureAdminComponent,
    MonCompteAdminComponent,
    CommandeArticleViewComponent,
    AccueilClientComponent,
    HeaderClientComponent,
    MenuClientComponent,
    CommandeClientViewComponent,
    EtiquetteAdminComponent,
    EtiquetteViewComponent,
    BoutiqueClientComponent,
    FicheProduitClientComponent,
    PanierClientComponent,
    FooterClientComponent,
    MonCompteMemorialComponent,
    FicheAnimalProprietaireComponent,
    BoutiqueClientCategorieComponent,
    FicheProduitTemplateComponent,
    HeaderClientBoutiqueComponent,
    QuiSommesNousComponent,
    HeaderClientMobileComponent,
    FooterClientMobileComponent,
    HeaderClientBoutiqueMobileComponent,


  ],
  imports: [
    NgxSpinnerModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    HttpClientModule,
    FormsModule,
    NgxScannerQrcodeModule,
    BrowserModule,
    AppRoutingModule,
    LabelModule,
    BrowserAnimationsModule,
    InputsModule,
    ButtonsModule,
    DialogsModule,
    NavigationModule,
    LayoutModule,
    GridModule,
    ExcelModule,
    IndicatorsModule,
    DateInputsModule,
    GuidedTourModule,
    DropDownsModule,
    ExcelExportModule,
    IconsModule,
    UploadsModule,
  ],
  providers: [
    httpInterceptorProviders,
    TokenService,
    GlobalService,
    { provide: MessageService, useClass: TradTelerikService },
    GuidedTourService,
    {
      // Set default locale to fr
      provide: LOCALE_ID,
      useValue: "fr",
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
