import { Component, Input, OnInit } from '@angular/core';
import { SiteCabinetVeterinaire } from '../../../_models/SiteCabinetVeterinaire';
import { State, process } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { SocieteService } from '../../../_services/SocieteService';


@Component({
  selector: 'app-parc-corps-view',
  templateUrl: './parc-corps-view.component.html',
  styleUrls: ['./parc-corps-view.component.scss']
})
export class ParcCorpsViewComponent implements OnInit {
  @Input() site: SiteCabinetVeterinaire = new SiteCabinetVeterinaire();
  @Input() nouveau: boolean = false;
  public gridState: State = {
    skip: 0,
    take: 50,
  };
  public gridData!: GridDataResult;

  constructor(private _SocieteService: SocieteService) { }

  async ngOnInit(): Promise<void> {

    //Aller chercher le site au complet
    if(!this.nouveau){
      this.site = (await this._SocieteService.GetEtablissementById(this.site.id)).objet
    }

    //Filtrer les commandes 
    this.site.commandes = this.site.commandes.filter(c => c.asso_StatutCommande_Commande.length == 1);
   
    this.site.commandes.forEach(c => { c.client.PrenomNom = c.client.prenom + ' ' + c.client.nom });
    this.dataStateChange(this.gridState)
  }

  public async dataStateChange(state: State) {
    this.gridState = state;
    await this.ChargerTableau();
  }
  ///charge les utilisateurs dans le tableau
  public async ChargerTableau() {
    // Init des données pour la grille
    this.gridData = process(this.site.commandes, this.gridState);
  }


}
