<app-header-admin></app-header-admin>
<app-loader *ngIf="loading"></app-loader>
<div id="habilitations">

  <div class="container">
    <div class="row">
      <div class="col-md-2">
        <app-menu-admin></app-menu-admin>
      </div>
      <div class="col-md-10 p-4">

        <h1>Approvisionnement d'étiquettes</h1>
        <br>
        <div>
          <button kendoButton (click)="AjouterSac()" themeColor="primary">Ajouter un approvisionnement d'étiquettes</button>
        </div>
        <br>
        <div class="row">
          <div class="col-12">
            <div class="grid-wrapper">
              <kendo-grid [data]="gridData" (dataStateChange)="dataStateChange($event)" [sortable]="true"
                [sort]="gridState.sort" [columnMenu]="true" [pageSize]="gridState.take" [skip]="gridState.skip"
                [height]="800" [pageable]="true" [filter]="gridState.filter" [filterable]="true"[resizable]="true"
                (edit)="ModifierSac($event)">
                <kendo-grid-column filter="date" field="dateApprovisionnement" title="Date de l'approvisionnement">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.dateApprovisionnement | date:'dd/MM/yyyy'}}
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="siteCabinetVeterinaire.CabinetVille" title="Société"> </kendo-grid-column>
                <kendo-grid-column title="Plage d'étiquettes">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.numeroSacInitial}} - {{dataItem.numeroSacFinal}}
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="nbEtiquettesAffectes" title="Nb étiquettes affectées"> </kendo-grid-column>
                <kendo-grid-column title="Action">
                  <ng-template kendoGridCellTemplate>
                    <button themeColor="primary" kendoGridEditCommand>Modifier</button>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>

            </div>
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
</div>


<ng-template #dialogActions>
  <button kendoButton (click)="closeSac()">Fermer</button>
  <button kendoButton themeColor="primary" [disabled]="!this.dialog.content.instance.validate()"
    (click)="EnregistrerSac()">Enregistrer</button>
</ng-template>
<ng-container #detailSacContainer></ng-container>