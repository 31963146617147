import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-client-mobile',
  templateUrl: './footer-client-mobile.component.html',
  styleUrls: ['./footer-client-mobile.component.scss']
})
export class FooterClientMobileComponent {
  GoToSite(url: string) {
    let a = document.createElement('a');
    a.href = url
    a.target = '_blank';
    a.click();
    document.removeChild(a);
  }
}
