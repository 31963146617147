import { Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { State, process } from '@progress/kendo-data-query';
import { GlobalService } from '../../_services/GlobalService';
import { TourneeViewComponent } from '../_Views/tournee-view/tournee-view.component';
import { Tournee } from '../../_models/Tournee';
import { TourneeService } from '../../_services/TourneeService';

@Component({
  selector: 'app-tournee-admin',
  templateUrl: './tournee-admin.component.html',
  styleUrls: ['./tournee-admin.component.scss']
})
export class TourneeAdminComponent {
  @ViewChild('dialogActions') dialogActions!: TemplateRef<unknown>;
  public dialog !: DialogRef;

  @ViewChild("detailTourneeContainer", { read: ViewContainerRef })
  public detailTourneeContainer!: ViewContainerRef;
  @ViewChild('tabstrip') ts!: TabStripComponent;
  public gridData!: GridDataResult;
  public gridState: State = {
    skip: 0,
    take: 25,
  };
  loading: boolean = false;
  public Tournees: Tournee[] = new Array()


  public constructor(private _TourneeService: TourneeService,
    public _GlobalService: GlobalService,
    private _dialogService: DialogService) { }

  ngOnInit(): void {
    //charge le tableau des utilisateurs
    this.ChargerListeTournee();
  }

  public async ChargerListeTournee() {
    this.loading = true;

    let reponse = await this._TourneeService.GetAllTournees();

    this.Tournees = reponse.objet;

    this.Tournees.map(tournee => {
      tournee.date = new Date(tournee.date);
    })

    //Filtrer par date
    this.Tournees = this.Tournees.sort((a, b) => { return a.date < b.date ? 1 : -1 })

    // Obtenir la date actuelle sans les heures, minutes et secondes
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Filtrer les tournées dont la date est égale ou supérieure à la date du jour
    this.Tournees = this.Tournees.filter(tournee => {
      const tourneeDate = new Date(tournee.date);
      tourneeDate.setHours(0, 0, 0, 0); // Ignore les heures, minutes, et secondes
      return tourneeDate >= today;
    });

    this.dataStateChange(this.gridState);
    this.loading = false;
  }

  public async dataStateChange(state: State) {
    this.gridState = state;
    await this.ChargerTableau();
  }
  ///charge les utilisateurs dans le tableau
  public async ChargerTableau() {
    // Init des données pour la grille
    this.gridData = process(this.Tournees, this.gridState);
  }

  public ouvreDetailTournee(Tournee: Tournee, nouveau: boolean) {
    this.dialog = this._dialogService.open({
      title: "Fiche de la tournée",
      width: '90%',
      height: '90%',
      //actions: [cancelAction, saveAction],
      actions: this.dialogActions,

      appendTo: this.detailTourneeContainer,
      closeTitle: "Fermer",
      content: TourneeViewComponent,
    });

    let DetailTournee = this.dialog.content.instance;
    DetailTournee.Tournee = Tournee;
    DetailTournee.nouveau = nouveau;
  }

  //ajouter un nouveau Tournee
  public AjouterTournee() {
    this.ouvreDetailTournee(new Tournee(), true);
  }

  //ferme la boite de dialogue
  public closeTournee(): void {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  ModifierTournee({ dataItem }: any) {
    this.ouvreDetailTournee(dataItem as Tournee, false);
  }

  public async EnregistrerTournee() {

    let DetailClient = (this.dialog.content.instance as TourneeViewComponent);

    if (DetailClient.validate() && await DetailClient.EnregistrerTournee()) {

      this.loading = true;

      await this.ChargerListeTournee();

      this.closeTournee();

      this.loading = false;
    }

  }
}
