<div id="header">
    <div id="Text">
        <p> <a class="link" (click)="RecupQuiSommesNous()" target="_blank">Qui sommes-nous</a></p>
        <p> <a class="link" href="https://www.monphoenix.fr/" target="_blank">Nos prestations</a></p>
        <p> <a class="link" href="/client/boutique">La boutique</a></p>
    </div>
    <div id="logo">
        <img src="https://www.monphoenix.fr/wp-content/uploads/2024/11/logo-horizontal.png" (click)="GoToAccueil()" alt="Logo" id="LogoPhoenix">
    </div>
    <div id="Btn">
        <a href="https://www.monphoenix.fr/le-memorial/" target="_blank" rel="noopener noreferrer"> <img
                src="../../../assets/Utilisateur/MemorialEnLigne.png" alt="Memorial" id="LogoMemorial">
        </a>

        <div (click)="GoToMonCompte()" id="ContainermonCompte">
            <img src="../../../assets/Utilisateur/MonCompte.png" alt="MonCompte" id="LogoMonCompte">
            <div id="TextMonCompte">
                <p id="TxtMonCompte">MON COMPTE</p>
                <p id="TxtParametres">Paramètres</p>
            </div>
        </div>
        <div (click)="GoToPanier()" id="ContainermonCompte">
            <img src="../../../assets/Utilisateur/Panier.png" alt="panier" id="LogoPanier">
            <div id="TextMonCompte">
                <p id="TxtMonCompte">Panier</p>
                <p id="TxtParametres">{{this._PanierService.GetTotalPanier()}} €</p>
            </div>
        </div>

    </div>
</div>