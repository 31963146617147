<div id="menu-admin">
    <div class="panelbar-wrapper">
        <kendo-panelbar *ngIf="this._GlobalService.isAdmin()" (stateChange)="stateChange($event)">
            <kendo-panelbar-item title="{{item.label}}" *ngFor="let item of menu"
                [selected]="item.route === selectedMenu" style="display: block;">
            </kendo-panelbar-item>
        </kendo-panelbar>
        <kendo-panelbar *ngIf="!this._GlobalService.isAdmin()" (stateChange)="stateChange($event)">
            <kendo-panelbar-item title="{{item.label}}" *ngFor="let item of menuVeterinaire"
                [selected]="item.route === selectedMenu" style="display: block;">
            </kendo-panelbar-item>
        </kendo-panelbar>
    </div>
</div>