import { Asso_Commande_Produit } from "./Asso_Commande_Produit ";
import { ProduitCategorie } from "./CategorieProduit ";
import { VariationProduitCouleur } from "./VariationProduitCouleur";
import { VariationProduitTaille } from "./VariationProduitTaille";


export class Produit {
    id: number = 0;
    nom: string = "";
    uRL: string = "";
    description: string = "";
    Taille: VariationProduitTaille = new VariationProduitTaille();
    Couleur: VariationProduitCouleur = new VariationProduitCouleur();
    prix: number = 0;
    Quantite: number = 0;
    imageUrl: string = "";
    asso_Commande_Produits: Asso_Commande_Produit[] = new Array<Asso_Commande_Produit>();
    produitCategories: ProduitCategorie[] = new Array<ProduitCategorie>();
    variationProduitsTailles: VariationProduitTaille[] = new Array<VariationProduitTaille>();
    variationProduitsCouleurs: VariationProduitCouleur[] = new Array<VariationProduitCouleur>();
}