import { Component, Input, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { DataModelCreateAccount } from '../../../_models/DataModelController/DataModelCreateAccount';
import { Role } from '../../../_models/Role';
import { Utilisateur } from '../../../_models/Utilisateur';
import { CabinetVeterinaire } from '../../../_models/CabinetVeterinaire';
import { SocieteService } from '../../../_services/SocieteService';
import { GlobalService } from '../../../_services/GlobalService';
import { DataModelAddCabinet } from '../../../_models/DataModelController/DataModelAddCabinet';
import { SiteCabinetVeterinaire } from '../../../_models/SiteCabinetVeterinaire';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AjoutSiteVeterinaireComponent } from '../ajout-site-veterinaire/ajout-site-veterinaire.component';
import { DataModelUpdateCabinet } from '../../../_models/DataModelController/DataModelUpdateCabinet';
import { TabStripComponent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-societe-view',
  templateUrl: './societe-view.component.html',
  styleUrls: ['./societe-view.component.scss']
})
export class SocieteViewComponent {
  public selectedTab: number = 0;
  public verifFormInformation: boolean = true;
  @Input() public cabinet!: CabinetVeterinaire;
  @Input() public nouveau!: Boolean;
  public CabinetAjout: DataModelAddCabinet = new DataModelAddCabinet()
  @ViewChild('infoForm') infoFormSubmit!: NgForm;
  messageErreurInformation: string = "";
  public gridData!: GridDataResult;
  public gridState: State = {
    skip: 0,
    take: 25,
  };
  @ViewChild('dialogActions') dialogActions!: TemplateRef<unknown>;
  public dialog !: DialogRef;
  @ViewChild('tabstrip') tabstrip!: TabStripComponent;
  @ViewChild("detailCompteContainer", { read: ViewContainerRef })
  public detailCompteContainer!: ViewContainerRef;
  loading: boolean = false;
  SiteCabinetModifier: SiteCabinetVeterinaire = new SiteCabinetVeterinaire()
  SiteCabinetAjouter: SiteCabinetVeterinaire = new SiteCabinetVeterinaire()
  public IndexTabStrip = 0;

  public constructor(private _dialogService: DialogService, public CabinetVeterinaireService: SocieteService, public GlobalService: GlobalService) {

  }

  public async listeSites() {
    this.loading = true;

    let reponse = await this.CabinetVeterinaireService.GetAllSiteByCabinetId(this.CabinetAjout.id);

    this.CabinetAjout.siteCabinets = reponse.objet;
    this.CabinetAjout.siteCabinets.forEach(x => {
      x.horraireDebutMatin = new Date(x.horraireDebutMatin);
      x.horraireFinMatin = new Date(x.horraireFinMatin);
      x.horraireDebutApresMidi = new Date(x.horraireDebutApresMidi);
      x.horraireFinApresMidi = new Date(x.horraireFinApresMidi);
    })

    this.dataStateChange(this.gridState);
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    //charge le cabinet
    this.chargerCabinet();
    await this.listeSites()
  }

  public async chargerCabinet() {
    if (!this.nouveau) {
      this.CabinetAjout.id = this.cabinet.id
      this.CabinetAjout.nom = this.cabinet.nom
      this.CabinetAjout.siteCabinets = this.cabinet.sitesCabinets
    }
  }

  public validate(): boolean {
    if (this.CabinetAjout.siteCabinets.length > 0) {
      return true
    } else {
      return false
    }
  }
  public async onSelect(e: any) {
    this.tabstrip.selectTab(e.index);
    this.IndexTabStrip = e.index;
  }

  ////////Sites
  public dataStateChange(state: State) {
    this.gridState = state;
    this.ChargerTableau();
  }

  public ChargerTableau() {
    // Init des données pour la grille
    this.gridData = process(this.CabinetAjout.siteCabinets!, this.gridState);
  }
  public ChargerTableauClose() {
    // Init des données pour la grille
    this.gridData = process(this.CabinetAjout.siteCabinets!, this.gridState);
    this.dialog.close();
  }

  public ouvreDetailSite(site: SiteCabinetVeterinaire, nouveau: boolean) {
    this.dialog = this._dialogService.open({
      title: "Fiche du site",
      width: '90%',
      height: '90%',
      //actions: [cancelAction, saveAction],
      actions: this.dialogActions,

      appendTo: this.detailCompteContainer,
      closeTitle: "Fermer",
      content: AjoutSiteVeterinaireComponent,
    });

    let DetailSite = this.dialog.content.instance;
    DetailSite.site = site;
    DetailSite.cabinet = this.CabinetAjout;
    DetailSite.nouveau = nouveau;
  }
  public AjouterSite() {
    this.ouvreDetailSite(new SiteCabinetVeterinaire(), true);
  }
  public closeSite(): void {
    if (this.dialog) {
      this.dialog.close();
    }
  }
  modifierSite({ dataItem }: any) {
    this.ouvreDetailSite(dataItem as SiteCabinetVeterinaire, false);
  }
  public async EnregistrerSiteCabinet() {
    //Initier la variable retour
    let retour = false;

    this.loading = true;
    if (this.nouveau) {
      let reponse = await this.CabinetVeterinaireService.AddCabinet(this.CabinetAjout);
      if (reponse.statusCode == 200) {
        this.verifFormInformation = true;
        retour = true;
      } else {
        this.verifFormInformation = false;
        this.messageErreurInformation = reponse.messageException;
      }
    } else {
      let data: DataModelUpdateCabinet = new DataModelUpdateCabinet()
      data.cabinetId = this.CabinetAjout.id
      data.nom = this.CabinetAjout.nom
      data.sitesCabinets = this.CabinetAjout.siteCabinets

      data.sitesCabinets.map(x => {
        x.horraireDebutMatin = new Date(x.horraireDebutMatin);
        x.horraireFinMatin = new Date(x.horraireFinMatin);
        x.horraireDebutApresMidi = new Date(x.horraireDebutApresMidi);
        x.horraireFinApresMidi = new Date(x.horraireFinApresMidi);
      })

      let reponse = await this.CabinetVeterinaireService.UpdateCabinet(data);
      if (reponse.statusCode == 200) {
        this.verifFormInformation = true;
        retour = true;
      } else {
        this.verifFormInformation = false;
        this.messageErreurInformation = reponse.messageException;
      }
    }

    this.loading = false;
    return retour;
  }

  SuivantSociete() {
    // Naviguer vers l'onglet "Animal"
    if (this.tabstrip) {
      this.IndexTabStrip += 1;
      this.tabstrip.selectTab(this.IndexTabStrip);
    }
  }

  IsSuivantValide(): boolean {
    if (this.CabinetAjout.nom != "") {
      return true
    } else {
      return false
    }
  }

}
