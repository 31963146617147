import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Commande } from '../../../_models/Commande';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { FicheAnimalViewComponent } from '../../_Views/fiche-animal-view/fiche-animal-view.component';
import { StatutCommandeService } from '../../../_services/StatutCommandeService';
import { DataModelUpdateStatutCommande } from '../../../_models/DataModelController/DataModelUpdateStatutCommande';
import { TypeSacCremation } from '../../../_models/TypeSacCremation';

@Component({
  selector: 'app-carte-cabinet-commandes',
  templateUrl: './carte-cabinet-commandes.component.html',
  styleUrls: ['./carte-cabinet-commandes.component.scss']
})
export class CarteCabinetCommandesComponent implements OnInit {
  @Input() Commandes: Commande[] = new Array();

  @ViewChild('dialogActions') dialogActions!: TemplateRef<unknown>;
  private dialog !: DialogRef;

  @ViewChild("detailConfirmationContainer", { read: ViewContainerRef })
  public detailConfirmationContainer!: ViewContainerRef;
  @ViewChild("detailViewContainer", { read: ViewContainerRef })
  public detailViewContainer!: ViewContainerRef;
  loading: boolean = false;
  messageErreurInformation: string = "";

  constructor(private _DialogService: DialogService, private _StatutCommandeService: StatutCommandeService) { }

  ngOnInit(): void {
    this.Commandes = this.Commandes.map((commande) => {
      return {
        ...commande,
        animal: {
          ...commande.animal,
          dateDeces: new Date(commande.animal.dateDeces)
        }
      }
    })
  }

  public isRetire(commande: Commande) {
    return commande.asso_StatutCommande_Commande.some(x => x.statutCommandeId == 2)
  }

  public OuvreFicheAnimal(event: MouseEvent, commande: Commande) {
    const element = event.target as HTMLElement; // Récupérer l'élément sur lequel l'utilisateur a cliqué
    if (element.tagName.toLowerCase() !== 'img') {
      this.dialog = this._DialogService.open({
        title: `Fiche de l'animal N° ${commande.detailCremation.sacId} `,
        width: '90%',
        height: '90%',
        //actions: [cancelAction, saveAction],
        actions: this.dialogActions,

        appendTo: this.detailViewContainer,
        closeTitle: "Fermer",
        content: FicheAnimalViewComponent,
      });

      let DetailClient = this.dialog.content.instance;
      DetailClient.commande = commande;
    }
  }

  public CloseView() {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  public async SaveView() {
    this.loading = true
    let DetailClient = this.dialog.content.instance as FicheAnimalViewComponent;

    if (DetailClient.validate() && await DetailClient.ModifierAnimal()) {
      this.CloseView()
      this.loading = false;
    }
  }

  public async RetireEtatCommande(indexCommande: number) {

    this.dialog = this._DialogService.open({
      title: `Annulation du scan`,
      content: `Êtes-vous sûr de vouloir annuler le scan de cette étiquette ?`,
      width: '90%',
      height: '30%',
      actions: [{ text: "Non" }, { text: "Oui", themeColor: "primary" }],
      closeTitle: "Fermer",
      appendTo: this.detailConfirmationContainer,

    });

    this.dialog.result.subscribe(async (result) => {

      let resulttext: any = JSON.parse(JSON.stringify(result))

      if (!(result instanceof DialogCloseResult) && resulttext.text == "Oui") {
        let data: DataModelUpdateStatutCommande = new DataModelUpdateStatutCommande()
        data.etiquette = this.Commandes[indexCommande].id
        data.typeSac = new TypeSacCremation()
        data.statuCommandeId = 2

        this.loading = true
        let reponse = await this._StatutCommandeService.UpdateRetirerStatutCommande(data)
        if (reponse.statusCode == 200) {
          //Le retirer
          this.Commandes[indexCommande].asso_StatutCommande_Commande = this.Commandes[indexCommande].asso_StatutCommande_Commande.filter(x => x.statutCommandeId != 2)
        } else {
          this.messageErreurInformation = reponse.messageException
        }
        this.loading = false
      }
    })
  }
}