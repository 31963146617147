import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ProduitService } from '../../_services/ProduitService';
import { ClientService } from '../../_services/ClientService';

@Component({
  selector: 'app-qui-sommes-nous',
  templateUrl: './qui-sommes-nous.component.html',
  styleUrls: ['./qui-sommes-nous.component.scss']
})
export class QuiSommesNousComponent implements OnInit {

  contenuQuiSommesNous: string = "";

  constructor(private _ClientService: ClientService) { }

  async ngOnInit(): Promise<void> {
    this.contenuQuiSommesNous = (await this._ClientService.GetPageQuiSommesNous()).objet

    // Extraire les liens CSS et les ajouter à la page
    const parser = new DOMParser();
    const doc = parser.parseFromString(this.contenuQuiSommesNous, 'text/html');
    const cssLinks = doc.querySelectorAll('link[rel="stylesheet"]');
  
    cssLinks.forEach(link => {
      const styleLink = document.createElement('link');
      styleLink.rel = 'stylesheet';
      styleLink.href = link.getAttribute('href')!;
      document.head.appendChild(styleLink);
    });
  }

}
