import { Component } from '@angular/core';

@Component({
  selector: 'app-header-client-mobile',
  templateUrl: './header-client-mobile.component.html',
  styleUrls: ['./header-client-mobile.component.scss']
})
export class HeaderClientMobileComponent {

  GoToAccueil() {
    location.href = "/client/accueil"
  }
  
  toggleMenu() {
    const menu = document.getElementById("menu")!;
    menu.style.display = menu.style.display === "block" ? "none" : "block";
  }
}