import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "./GlobalService";
import { ReponseAPI } from "../_models/ReponseAPI";
import { DataModelGetFactures } from "../_models/DataModelController/DataModelFacture";
import { Produit } from "../_models/Produit";

@Injectable({
    providedIn: 'root'
})

export class ProduitService {

    produits: Produit[] = new Array<Produit>()
    private nomCategorie: string = "Urnes Chien"

    public async SetNomCategorie(nomCategorie: string) {
        if (nomCategorie == null || nomCategorie == undefined || nomCategorie == "") {
            this.produits = (await this.GetProduitByCategorieName("Urnes Chien")).objet
        } else {
            this.nomCategorie = nomCategorie
            this.produits = (await this.GetProduitByCategorieName(this.nomCategorie)).objet
        }
    }

    public GetNomCategorie(): string {
        return this.nomCategorie
    }

    public constructor(private http: HttpClient, private router: Router, private _GlobalService: GlobalService) { }

    public async GetAllProduits(): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Produit/Produits")
    }
    public async GetProduitById(IdProduit: number): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Produit/Produits/" + IdProduit)
    }
    public async GetProduitByCategorieName(categorie: string): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Produit/Produits/Categories/" + categorie)
    }
    public async GetProduitByIds(IdProduit: number[]): Promise<ReponseAPI> {
        return await this._GlobalService.SendPOSTRequest("Produit/Produits/Ids", IdProduit)
    }
    public async GetProduitCategories(): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Produit/Categories")
    }
    public async GetQuiSommesNous(): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Produit/QuiSommesNous")
    }

}