import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-confirmer-suppression-tournee-view',
  templateUrl: './confirmer-suppression-tournee-view.component.html',
  styleUrls: ['./confirmer-suppression-tournee-view.component.scss']
})
export class ConfirmerSuppressionTourneeViewComponent {
  @Output() confirmer = new EventEmitter<void>();
  @Output() annuler = new EventEmitter<void>();

  Confirmer() {
    this.confirmer.emit();
  }

  Annuler() {
    this.annuler.emit();
  }
}
