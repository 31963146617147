import { Component, Input, ViewChild } from '@angular/core';
import { Commande } from '../../../_models/Commande';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { ReponseAPI } from '../../../_models/ReponseAPI';
import { CommandeService } from '../../../_services/CommandeService';
import { NgForm } from '@angular/forms';
import { DataModelUpdateCommandeArticle } from '../../../_models/DataModelController/DataModelUpdateCommandeArticle';

@Component({
  selector: 'app-commande-article-view',
  templateUrl: './commande-article-view.component.html',
  styleUrls: ['./commande-article-view.component.scss']
})
export class CommandeArticleViewComponent {

  loading: boolean = false;
  @Input() public commande!: Commande;
  @Input() public nouveau!: Boolean;
  messageErreurInformation: string = ""
  @ViewChild('tabstrip') tabstrip!: TabStripComponent;
  public IndexTabStrip = 0;
  @ViewChild('infoForm') infoFormSubmit!: NgForm;
  public verifFormInformation: boolean = true;


  public constructor(
    private _CommandeService: CommandeService) { }


  onSelect($event: any) {
    this.tabstrip.selectTab($event.index);
    this.IndexTabStrip = $event.index;
  }

  public validate(): boolean {

    //Si c'est un nouveau client, vérifier tous les champs du client
    if (this.commande.client.nom == "" ||
      this.commande.client.prenom == "" ||
      this.commande.client.email == "" ||
      this.commande.client.telephone == "" ||
      this.commande.client.adresse == "" ||
      this.commande.client.ville == "" ||
      this.commande.client.codePostal == "") {
      return false
    } else {
      return true
    }

  }

  async EnregistrerCommande() {
    this.loading = true
    let retour = true;
    let reponse: ReponseAPI = new ReponseAPI()

    let data: DataModelUpdateCommandeArticle = new DataModelUpdateCommandeArticle()
    data.archiver = this.commande.archiver
    data.idCommande = this.commande.id

    reponse = await this._CommandeService.UpdateCommandeArticle(data);

    if (reponse.statusCode == 200) {
      this.verifFormInformation = true;
      retour = true;
    } else {
      this.verifFormInformation = false;
      this.messageErreurInformation = reponse.messageException;
      retour = false;
    }
    this.loading = false
    return retour
  }

}
