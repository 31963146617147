import { Component, Input, ViewChild } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { ClientService } from '../../../_services/ClientService';
import { Client } from '../../../_models/Client';
import { GlobalService } from '../../../_services/GlobalService';
import { NgForm } from '@angular/forms';
import { Utilisateur } from '../../../_models/Utilisateur';

@Component({
  selector: 'app-client-view',
  templateUrl: './client-view.component.html',
  styleUrls: ['./client-view.component.scss']
})
export class ClientViewComponent {
  @Input() public client!: Client;
  @Input() public nouveau!: Boolean;
  @ViewChild('infoForm') infoFormSubmit!: NgForm;
  public verifFormInformation: boolean = true;
  messageErreurInformation: string = "";
  loading: boolean = false;
  Clients: Client[] = [];
  //#region Grid
  public gridData!: GridDataResult;
  public gridState: State = {
    skip: 0,
    take: 25,
  };
  public async dataStateChange(state: State) {
    this.gridState = state;
    await this.ChargerTableau();
  }
  ///charge les utilisateurs dans le tableau
  public async ChargerTableau() {
    // Init des données pour la grille
    this.gridData = process(this.Clients, this.gridState);
  }

  //#endregion

  public constructor(private ClientSerice: ClientService, public _GlobalService: GlobalService) {

  }

  async ngOnInit(): Promise<void> {
    if (!this.nouveau) {
     this.client.role = "client"
    }
  }
  public async chargerClient() {


  }

  public isFormValide() {
    this.infoFormSubmit.ngSubmit.emit();

    if (!this.infoFormSubmit.invalid) {
      return true;
    } else {
      return false;
    }
  }

  public validate() {
    this.infoFormSubmit.ngSubmit.emit();
    this.infoFormSubmit.control.markAllAsTouched();
    if (!this.infoFormSubmit.invalid) {
      return true
    }
    else {
      return false;
    }
  }

  public async EnregistrerClient() {
    let retour = false;
    //si nouvel utilisateur

    this.loading = true;
    if (this.nouveau) {
      let reponse = await this.ClientSerice.AjouterClient(this.client);
      if (reponse.statusCode == 200) {
        this.verifFormInformation = true;
        retour = true;
      } else {
        this.verifFormInformation = false;
        if (reponse.messageException != undefined) {
          this.messageErreurInformation = reponse.messageException;
        }

      }
      this.loading = false

    } else {

      //utilisateur existant
      let reponse = await this.ClientSerice.ModifierClient(this.client);

      if (reponse.statusCode == 200) {
        this.verifFormInformation = true;
        retour = true;
      } else {
        this.verifFormInformation = false;
        if (reponse.messageException != undefined) {
          this.messageErreurInformation = reponse.messageException;
        }
      }
    }
    return retour;
  }
}
