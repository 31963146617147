import { Component, OnInit, EventEmitter, ViewChild, ViewContainerRef, TemplateRef } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Utilisateur } from '../../_models/Utilisateur';
import { UtilisateurService } from '../../_services/UtilisateurService';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GlobalService } from '../../_services/GlobalService';
import { CompteViewComponent } from '../_Views/compte-view/compte-view.component';
import { TabStripComponent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-comptes-admin',
  templateUrl: './comptes-admin.component.html',
  styleUrls: ['./comptes-admin.component.scss']
})
export class ComptesAdminComponent {

  @ViewChild('dialogActions') dialogActions!: TemplateRef<unknown>;
  public dialog !: DialogRef;

  @ViewChild("detailCompteContainer", { read: ViewContainerRef })
  public detailCompteContainer!: ViewContainerRef;
  @ViewChild('tabstrip') ts!: TabStripComponent;
  public gridData!: GridDataResult;
  public gridState: State = {
    skip: 0,
    take: 25,
  };
  public selectedTabClient: number = 0;
  public Utilisateurs: Utilisateur[] = []
  loading: boolean = false;

  public constructor(private _UtilisateurService: UtilisateurService,
    public _GlobalService: GlobalService,
    private _dialogService: DialogService) { }

  ngOnInit(): void {
    //charge le tableau des utilisateurs
    this.listeUtilisateur();

    this._GlobalService.selectedTabClient.subscribe(selectedTabClient => {
      this.selectedTabClient = selectedTabClient;
    });

  }
  public async listeUtilisateur() {
    this.loading = true;

    let reponse = await this._UtilisateurService.GetAllUsers();

    this.Utilisateurs = reponse.objet;

    this.Utilisateurs.forEach(user => {
      if (user.role == 'vétérinaire' && user.siteCabinetVeterinaires != null && user.siteCabinetVeterinaires != undefined) {
        user.nomsCabinet = ""

        user.siteCabinetVeterinaires.forEach(site => {
          user.nomsCabinet += (site.cabinetVeterinaire.nom + " - " + site.adresse + "\n");
        })

      } else {
        user.nomsCabinet = ""
      }
    })

    this.dataStateChange(this.gridState);
    this.loading = false;
  }

  public async dataStateChange(state: State) {
    this.gridState = state;
    await this.ChargerTableau();
  }
  ///charge les utilisateurs dans le tableau
  public async ChargerTableau() {
    // Init des données pour la grille
    this.gridData = process(this.Utilisateurs, this.gridState);
  }

  //ouvre la fenêtre client
  public ouvreDetailClient(client: Utilisateur, nouveau: boolean) {
    this.dialog = this._dialogService.open({
      title: "Fiche personelle",
      width: '90%',
      height: '90%',
      //actions: [cancelAction, saveAction],
      actions: this.dialogActions,

      appendTo: this.detailCompteContainer,
      closeTitle: "Fermer",
      content: CompteViewComponent,
    });

    let DetailClient = this.dialog.content.instance;
    DetailClient.client = client;
    DetailClient.nouveau = nouveau;
  }

  //ajouter un nouveau client
  public ajoutClient() {
    this.ouvreDetailClient(new Utilisateur(), true);
  }

  //ferme la boite de dialogue
  public closeClient(): void {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  modifieCompte({ dataItem }: any) {
    this.ouvreDetailClient(dataItem as Utilisateur, false);
  }

  public async enregistreClient() {

    let DetailClient = this.dialog.content.instance;

    if (DetailClient.validate() && await DetailClient.enregistreUtilisateur()) {

      this.loading = true;

      await this.listeUtilisateur();

      this.closeClient();

      this.loading = false;
    }

  }



}
