<app-header-client *ngIf="!this.isMobile"></app-header-client>
<app-header-client-mobile *ngIf="this.isMobile"></app-header-client-mobile>
<app-loader *ngIf="loading"></app-loader>
<div id="Container" *ngIf="!this.isClearPanier ">
    <p id="TxtCommander">COMMANDER</p>
    <p id="TxtPanier">VOTRE PANIER</p>
    <div id="ContainerTableRecap" *ngIf="!this.isMobile">
        <table>
            <thead>
                <tr>
                    <th id="ColArticle" scope="col">Articles</th>
                    <th class="Center" scope="col">Prix unitaire</th>
                    <th class="Center" scope="col">Variation</th>
                    <th class="Center" scope="col">Quantité</th>
                    <th class="Center" scope="col">Prix Total</th>
                    <th class="Center" scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let article of this._PanierService.GetProduitspanier()">
                    <td id="ColArticles">
                        <img id="ImageProduit" [src]="this._PanierService.getImageproduit(article)">
                        <p id="TxtNomArticle">{{ article.nom }}</p>
                    </td>
                    <td class="Center">
                        <p id="TxtPrixUnitaire">{{ getPrixArticle(article) }} €</p>
                    </td>
                    <td class="Center">
                        <kendo-dropdownlist *ngIf="isProduitVariations(article)" rounded="full" valueField="id"
                            textField="nomVariation" name="variationProduit" [data]="article.variationsProduits"
                            [(ngModel)]="article.variationProduit"></kendo-dropdownlist>
                    </td>
                    <td class="Center"> <kendo-dropdownlist *ngIf="!article.nom.includes('Crémation')" rounded="full"
                            (selectionChange)="this._PanierService.changeQuantite($event,article.id)" [data]="Quantite"
                            [(ngModel)]="article.Quantite"></kendo-dropdownlist>
                    </td>
                    <td class="Center">
                        <p id="TxtPrixTotal">{{ getPrixArticle(article) * article.Quantite }} €</p>
                    </td>
                    <td class="Center"> <img style="cursor: pointer;"
                            (click)="this._PanierService.changeQuantite(0,article.id)"
                            src="../../../assets/Utilisateur/poubelle.png" alt="poubelle"></td>
                </tr>
                <tr *ngIf="!this.isMobile">
                    <td colspan="3"></td>
                    <td colspan="3">
                        <div>
                            <input [(ngModel)]="isCGVOK" type="checkbox" id="CGV" name="CGV" value="CGV" required>
                            <label id="CGV" for="CGV">J'accepte les <a id="CGVTexte" (click)="OpenCGV()">conditions
                                    générales de vente</a></label>
                        </div>
                        <button id="BtnContinuerAchat" [disabled]="!isOkPaiement()" (click)="ConfirmerPaiement()">PASSER
                            LA
                            COMMANDE <span>➞</span></button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div id="Recapitulatif" *ngIf="!this.isMobile">
            <p id="TxtRecapitulatif">Récapitulatif</p>
            <p id="TxtRecapitulatifQuantite">Il y a {{this._PanierService.GetTotalQuantite()}} article(s) dans votre
                panier</p>
            <p id="TxtTotal">Total TTC
                <span id="TxtTotalPanier">{{this.GetPrixPanier() | number:'1.2-2'}} € (dont {{
                    _PanierService.calculerTVA() | number:'1.2-2' }} € TVA)
                </span>
            </p>
        </div>
    </div>
    <br>
    <div id="ContainerTableRecapMobile" *ngIf="this.isMobile">
        <table>
            <thead>
                <tr>
                    <th id="ColArticle">Articles</th>
                    <th class="Center" scope="col"></th>
                    <th class="Center" scope="col"></th>
                </tr>
            </thead>

            <tbody>
                <tr id="TrLigneProduit" *ngFor="let article of this._PanierService.GetProduitspanier()">
                    <td id="ColImage">
                        <img id="ImageProduit" [src]="this._PanierService.getImageproduit(article)">
                    </td>
                    <td>
                        <p id="TxtNomArticle">{{ article.nom }}</p>
                        <p id="TxtPrixUnitaire">{{ getPrixArticle(article) | number:'1.2-2' }} €</p>
                        <kendo-dropdownlist id="DrpdwnTaille" *ngIf="isProduitVariations(article)" rounded="full"
                            valueField="id" textField="taille" [data]="article.variationsProduits"
                            [(ngModel)]="article.variationProduit">
                        </kendo-dropdownlist>
                        <br>
                        <kendo-dropdownlist *ngIf="!article.nom.includes('Crémation')" rounded="full"
                            (selectionChange)="this._PanierService.changeQuantite($event,article.id)" [data]="Quantite"
                            [(ngModel)]="article.Quantite">
                        </kendo-dropdownlist>
                        <br>
                        <p id="TxtPrixTotal">{{ (getPrixArticle(article) * article.Quantite)| number:'1.2-2' }} €</p>
                    </td>
                    <td id="ColImagePoubelleMobile">
                        <img style="cursor: pointer;" (click)="this._PanierService.changeQuantite(0,article.id)"
                            src="../../../assets/Utilisateur/poubelle.png" alt="poubelle">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div id="Recapitulatif" *ngIf="this.isMobile">
        <p id="TxtRecapitulatif">Récapitulatif</p>
        <p id="TxtRecapitulatifQuantite">Il y a {{this._PanierService.GetTotalQuantite()}} article(s) dans votre
            panier</p>
        <br>
        <p id="Ligne"></p>
        <p id="TxtTotal">Total TTC <br>
            <span id="TxtTotalPanier">{{this.GetPrixPanier() | number:'1.2-2'}} € (dont {{
                _PanierService.calculerTVA() | number:'1.2-2' }} € TVA)
            </span>
        </p>
    </div>
    <div *ngIf="this.isMobile" class="ContainerCGVMobile">
        <div class="checkbox-container">
            <input [(ngModel)]="isCGVOK" type="checkbox" id="CGV" name="CGV" value="CGV" required>
            <label for="CGV">J'accepte les <a id="CGVTexte" (click)="OpenCGV()">conditions générales de
                    vente</a></label>
        </div>

        <button id="BtnContinuerAchat" [disabled]="!isOkPaiement()" (click)="ConfirmerPaiement()">PASSER
            LA
            COMMANDE <span>➞</span></button>
    </div>
</div>
<div id="Container" *ngIf="this.isClearPanier ">
    <p id="TxtCommander">COMMANDER</p>
    <p id="TxtPanier" *ngIf="!this.isMobile">MERCI POUR VOTRE COMMANDE</p>
    <p id="TxtPanier" *ngIf="this.isMobile">MERCI POUR <br>VOTRE COMMANDE</p>

    <p>Mon Phoenix vous remercie pour votre commande, vous recevrez prochainement un mail de confirmation sur votre
        adresse mail</p>

</div>