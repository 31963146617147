import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { SiteCabinetVeterinaire } from '../../../_models/SiteCabinetVeterinaire';
import { Tournee } from '../../../_models/Tournee';
import { TourneeService } from '../../../_services/TourneeService';
import { SocieteService } from '../../../_services/SocieteService';
import { GlobalService } from '../../../_services/GlobalService';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { TourneeEtablissement } from '../../../_models/TourneeEtablissement';
import { DataModelAddTournee } from '../../../_models/DataModelController/DataModelAddTournee';
import { DataModelGetSites } from '../../../_models/DataModelController/DataModelGetSites';

@Component({
  selector: 'app-tournee-view',
  templateUrl: './tournee-view.component.html',
  styleUrls: ['./tournee-view.component.scss']
})
export class TourneeViewComponent implements OnInit {

  @ViewChild("detailTourneeContainer", { read: ViewContainerRef })
  public detailCompteContainer!: ViewContainerRef;
  public selectedTab: number = 0;
  public verifFormInformation: boolean = true;
  @Input() public Tournee!: Tournee;
  @Input() public nouveau!: Boolean;
  @ViewChild('infoForm') infoFormSubmit!: NgForm;
  messageErreurInformation: string = "";
  loading: boolean = false;
  SelectedEtablissement: SiteCabinetVeterinaire = new SiteCabinetVeterinaire();
  @ViewChild('dialogActions') dialogActions!: TemplateRef<unknown>;
  private dialog !: DialogRef;
  SitesVeterinaires: SiteCabinetVeterinaire[] = [];
  AllSitesVeterinaires: SiteCabinetVeterinaire[] = [];

  public gridData!: GridDataResult;
  public gridState: State = {
    skip: 0,
    take: 50,
  };

  totalPoidsTournee: number = 0;
  totalNbCorps: number = 0;

  public constructor(public _TourneeService: TourneeService, public _GlobalService: GlobalService, private _dialogService: DialogService, private _SiteCabinetService: SocieteService) { }

  async ngOnInit(): Promise<void> {

    this.loading = true

    let data: DataModelGetSites = new DataModelGetSites();
    data.raccourci = true;
    data.utilisateurId = "";

    this.SitesVeterinaires = (await this._SiteCabinetService.GetAllSitesTournee(data)).objet;


    this.SitesVeterinaires = this.SitesVeterinaires.map(etablissement => ({
      ...etablissement,
      CabinetVille: `${etablissement.cabinetVeterinaire.nom} ${etablissement.ville}`,
      totalPoids: etablissement.commandes
        .filter(x => !x.isCremationTermine && x.asso_StatutCommande_Commande.length == 1 && x.asso_StatutCommande_Commande[0].statutCommandeId == 1)
        .map(a => a.animal.poids)
        .reduce((a, b) => a + b, 0),
      nbCorps: etablissement.commandes
        .filter(x => !x.isCremationTermine && x.asso_StatutCommande_Commande.length == 1 && x.asso_StatutCommande_Commande[0].statutCommandeId == 1).length,

    }))

    this.SitesVeterinaires = this.SitesVeterinaires.filter(x => x.cabinetVeterinaire.nom != 'Phoenix')

    this.AllSitesVeterinaires = this.SitesVeterinaires

    if (!this.nouveau) {
      this.Tournee.tourneeEtablissements.forEach(etablissement => {
        etablissement.siteCabinetVeterinaire.CabinetVille = `${etablissement.siteCabinetVeterinaire.cabinetVeterinaire.nom} ${etablissement.siteCabinetVeterinaire.ville}`
        if (etablissement.siteCabinetVeterinaire.commandes != undefined && etablissement.siteCabinetVeterinaire.commandes.length > 0) {
          etablissement.siteCabinetVeterinaire.totalPoids = etablissement.siteCabinetVeterinaire.commandes.filter(x => !x.isCremationTermine).map(a => a.animal.poids).reduce((a, b) => a + b, 0)
          etablissement.siteCabinetVeterinaire.nbCorps = etablissement.siteCabinetVeterinaire.commandes.filter(x => !x.isCremationTermine && x.asso_StatutCommande_Commande.length == 1 && x.asso_StatutCommande_Commande[0].statutCommandeId == 1).length
        } else {
          etablissement.siteCabinetVeterinaire.totalPoids = 0
        }
      })
    }

    this.dataStateChange(this.gridState)
    this.UpdatePoidsTournee()
    this.UpdateNbCorpsTotal()
    this.loading = false
  }

  public validate() {

    if (this.Tournee.tourneeEtablissements.length == 0 || this.Tournee.nom == "") {
      return false
    } else {
      return true
    }
  }

  SupprimerSite({ dataItem }: any) {
    const index = this.Tournee.tourneeEtablissements.indexOf(dataItem, 0);
    if (index > -1) {
      this.Tournee.tourneeEtablissements.splice(index, 1);
    }

    this.SelectedEtablissement = new SiteCabinetVeterinaire();

    this.dataStateChange(this.gridState)
    this.UpdatePoidsTournee()
    this.UpdateNbCorpsTotal()
  }

  UpdatePoidsTournee() {
    this.totalPoidsTournee = this.Tournee.tourneeEtablissements.map(a => a.siteCabinetVeterinaire.totalPoids).reduce((a, b) => a + b, 0)
  }
  UpdateNbCorpsTotal() {
    this.totalNbCorps = this.Tournee.tourneeEtablissements.map(a => a.siteCabinetVeterinaire.commandes
      .filter(x => !x.isCremationTermine && x.asso_StatutCommande_Commande.length == 1 && x.asso_StatutCommande_Commande[0].statutCommandeId == 1)
      .length
    ).reduce((a, b) => a + b, 0)
  }

  AjouterEtablissementTournee(event: any) {
    //Ajouter si pas déja présent
    if (!this.Tournee.tourneeEtablissements.some(e => e.siteCabinetVeterinaire.id == event.id)) {
      let tourneeEtablissement: TourneeEtablissement = new TourneeEtablissement();
      tourneeEtablissement.siteCabinetVeterinaire = event
      tourneeEtablissement.tourneeId = this.Tournee.id

      this.Tournee.tourneeEtablissements.push(tourneeEtablissement)
      this.dataStateChange(this.gridState)
    }
    this.UpdatePoidsTournee()
    this.UpdateNbCorpsTotal()
  }

  //enregistre l'utilisateur
  public async EnregistrerTournee() {
    let retour = false;

    this.loading = true;

    this.Tournee.tourneeEtablissements.forEach(etablissement => {
      etablissement.tourneeId = this.Tournee.id
    })
    let data: DataModelAddTournee = new DataModelAddTournee();
    data.date = this.Tournee.date
    data.tourneeId = this.Tournee.id
    data.nom = this.Tournee.nom
    
    this.Tournee.tourneeEtablissements.forEach(etablissement => {
      data.siteCabinetVeterinairesid.push(etablissement.siteCabinetVeterinaire.id)
    })


    if (this.nouveau) {
      let reponse = await this._TourneeService.AddTournee(data);
      if (reponse.statusCode == 200) {
        this.verifFormInformation = true;
        retour = true;
      } else {
        this.verifFormInformation = false;
        if (reponse.messageException != undefined) {
          this.messageErreurInformation = reponse.messageException;
        }

      }


    } else {
      //utilisateur existant
      let reponse = await this._TourneeService.UpdateTournee(data);

      if (reponse.statusCode == 200) {
        this.verifFormInformation = true;
        retour = true;
      } else {
        this.verifFormInformation = false;
        if (reponse.messageException != undefined) {
          this.messageErreurInformation = reponse.messageException;
        }
      }
    }

    this.loading = false;
    return retour;
  }

  public async dataStateChange(state: State) {
    this.gridState = state;
    await this.ChargerTableau();
  }
  ///charge les utilisateurs dans le tableau
  public async ChargerTableau() {
    // Init des données pour la grille
    this.gridData = process(this.Tournee.tourneeEtablissements, this.gridState);
  }

  handleFilterEtablissementveterinaire(value: any) {
    this.SitesVeterinaires = this.AllSitesVeterinaires.filter(
      etablissement =>
        etablissement.CabinetVille
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
    );
  }

}
