import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-client',
  templateUrl: './footer-client.component.html',
  styleUrls: ['./footer-client.component.scss']
})
export class FooterClientComponent {

  GoToSite(url:string){
    let a = document.createElement('a');
    a.href = url
    a.target = '_blank';
    a.click();
    document.removeChild(a);
  }

}
