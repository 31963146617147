<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <form #infoForm="ngForm" name="Form">
                <kendo-tabstrip (tabSelect)="onSelect($event)" #tabstrip>
                    <kendo-tabstrip-tab title="Informations du cabinet" [selected]="true" #tabInformation>
                        <ng-template kendoTabContent>
                            <h2>Informations de la clinique</h2>
                            <br><br>
                            <div class="row">
                                <div class="col-md-6">
                                    <kendo-label class="k-form" text="Nom *">
                                        <kendo-textbox placeholder="Nom" name="nom" [(ngModel)]="this.CabinetAjout.nom"
                                            [clearButton]="true" required>
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="pencil"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                            </div>

                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="Sites vétérinaires" [disabled]="this.CabinetAjout.nom == ''"
                        [selected]="false" #tabInformation>
                        <ng-template kendoTabContent>
                            <h2>Sites vétérinaires</h2>
                            <br><br>
                            <div *ngIf="!this.verifFormInformation" class="alert alert-danger">
                                {{messageErreurInformation}}
                            </div>
                            <div>
                                <button kendoButton (click)="AjouterSite()" themeColor="primary"
                                    style=" width: 10vw;">Ajouter un site</button>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12">
                                    <div class="grid-wrapper">
                                        <kendo-grid [data]="gridData" (dataStateChange)="dataStateChange($event)"
                                            (edit)="modifierSite($event)" [sortable]="true" [sort]="gridState.sort"
                                            [columnMenu]="true" [pageSize]="gridState.take" [skip]="gridState.skip"
                                            [height]="500" [pageable]="true" [filter]="gridState.filter"
                                            [filterable]="true">
                                            <kendo-grid-column field="cabinetVeterinaire.nom" title="Clinique">
                                            </kendo-grid-column>
                                            <kendo-grid-column field="telephone" title="Téléphone"> </kendo-grid-column>
                                            <kendo-grid-column field="email" title="Email"> </kendo-grid-column>
                                            <kendo-grid-column field="adresse" title="Adresse"> </kendo-grid-column>
                                            <kendo-grid-column field="codePostal" title="CodePostal">
                                            </kendo-grid-column>
                                            <kendo-grid-column field="ville" title="Ville"> </kendo-grid-column>
                                            <kendo-grid-column field="siret" title="N° Siret"> </kendo-grid-column>
                                            <kendo-grid-column title="Action">
                                                <ng-template kendoGridCellTemplate>
                                                    <button themeColor="primary" kendoGridEditCommand>Modifier</button>
                                                </ng-template>
                                            </kendo-grid-column>
                                        </kendo-grid>
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </form>
        </div>
    </div>
</div>
<ng-template #dialogActions>
    <button kendoButton (click)="closeSite()">Fermer</button>
    <button kendoButton themeColor="primary" (click)="this.ChargerTableauClose()"
        [disabled]="!this.dialog.content.instance.validate()">Enregistrer</button>
</ng-template>

<ng-container #detailCompteContainer></ng-container>