<div id="card" *ngFor="let commande of Commandes">
    <div id="PremiereColonne">
        <img id="LogoAnimal" src="../../../assets/TechnicienChauffeur/chat.png" alt="logoChat"
            *ngIf="commande.animal.categorieAnimal.nom == 'Chat'">
        <img id="LogoAnimal" src="../../../assets/TechnicienChauffeur/chien.png" alt="logoChien"
            *ngIf="commande.animal.categorieAnimal.nom == 'Chien'">
        <img id="LogoAnimal" src="../../../assets/TechnicienChauffeur/lapin.svg" alt="logoChien"
            *ngIf="commande.animal.categorieAnimal.nom == 'Petit ruminant'">
        <p>
            N° <b>{{commande.detailCremation.sacId}}</b>
        </p>
        <div id="Poids">
            <img src="../../../assets/TechnicienChauffeur/kilo.png" alt="kilo">
            <b id="PoidsAnimal">{{commande.animal.poids}}</b>
        </div>
    </div>
    <div id="SecondeColonne">
        <p [ngStyle]="{'color':  commande.animal.dateArrivee < dateAlert ? 'red' : 'black'}">Date d'arrivé :
            <b><br>{{commande.animal.dateArrivee |date : "dd/MM/yyyy HH:mm" }}</b>
            <br>
            <span>Nb jours depuis le décès : {{commande.nbJourDeces}}</span>
            <br>
            Type de crémation :
        </p>
        <ng-container [ngSwitch]="commande.detailCremation.typeCremationFinal.id">
            <p id="TypeCremationCollective" *ngSwitchCase="1">Collective</p>
            <p id="TypeCremationIndividuelle" *ngSwitchCase="2">Individuelle</p>
            <p id="TypeCremationIndividuelleCeremonie" *ngSwitchCase="3">Individuelle ++</p>
        </ng-container>
    </div>
    <br>
</div>