import { Component, OnInit } from '@angular/core';
import { Produit } from '../../_models/Produit';
import { ProduitService } from '../../_services/ProduitService';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-boutique-client',
  templateUrl: './boutique-client.component.html',
  styleUrls: ['./boutique-client.component.scss']
})
export class BoutiqueClientComponent implements OnInit {


  Allproduits: Produit[] = []
  produitsChien: Produit[] = []
  produitsChat: Produit[] = []
  produitsNAC: Produit[] = []
  produitsCadresPhotos: Produit[] = []
  produitsCoffret: Produit[] = []
  produitsBijoux: Produit[] = []
  nomCategories: string[] = []
  nomCategoriesSelect: string[] = []
  IsMobile: boolean = false
  constructor(private _produitService: ProduitService, private _device: DeviceDetectorService) { }

  async ngOnInit(): Promise<void> {
    //Venir charger la liste des produits
    this.Allproduits = (await this._produitService.GetAllProduits()).objet

    this.IsMobile = this._device.isMobile()

    //Faire les affectations en fonction des catégories
    this.produitsChien = this.Allproduits.filter(p => p.produitCategories.some(cat => cat.categorieProduit.nom == "Urnes Chien")).slice(0, 3)
    this.produitsChat = this.Allproduits.filter(p => p.produitCategories.some(cat => cat.categorieProduit.nom == "Urnes Chat")).slice(0, 3)
    this.produitsNAC = this.Allproduits.filter(p => p.produitCategories.some(cat => cat.categorieProduit.nom == "Urnes NAC")).slice(0, 3)
    this.produitsCadresPhotos = this.Allproduits.filter(p => p.produitCategories.some(cat => cat.categorieProduit.nom == "Cadre Photos")).slice(0, 3)
    this.produitsCoffret = this.Allproduits.filter(p => p.produitCategories.some(cat => cat.categorieProduit.nom == "Coffret")).slice(0, 3)
    this.produitsBijoux = this.Allproduits.filter(p => p.produitCategories.some(cat => cat.categorieProduit.nom == "Bijoux")).slice(0, 3)

    this.nomCategories = this.Allproduits.map(p => p.produitCategories.map(c => c.categorieProduit.nom)).reduce((a, b) => a.concat(b), []).filter((value, index, self) => self.indexOf(value) === index)

  }


  GoToProduits(nomCategorie: string) {
    if (nomCategorie == '') {
      location.href = "/client/boutique/produits?categorie=" + nomCategorie
    } else {
      location.href = "/client/boutique/produits"
    }
  }

}
