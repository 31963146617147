import { Injectable } from "@angular/core"
import { PanierModel } from "../_models/PanierModel"
import { Produit } from "../_models/Produit"
import { ProduitService } from "./ProduitService"
import { CommandeService } from "./CommandeService"
import { ClientService } from "./ClientService"
import { Commande } from "../_models/Commande"
import { OptionVariation } from "../_models/OptionVariation"

@Injectable({
  providedIn: 'root'
})
export class PanierService {

  constructor(private _ProduitService: ProduitService, private _ClientService: ClientService) { }

  //#region Panier
  prixPanier: number = 0
  Panier: PanierModel[] = []
  private Produits: Produit[] = []

  public GetProduitspanier() {
    return this.Produits
  }

  getImageproduit(article: Produit) {
    let panier: PanierModel[] = JSON.parse(localStorage.getItem("Panier")!)

    //Prendre le produit dans le panier
    let produit = panier.find(x => x.produit.id == article.id)?.produit

    if (produit) {
      if (produit.variationProduit != null && produit.variationProduit.nomVariation != "") {
        return produit.variationProduit.imageVariationURL
      } else {
        return article.imageUrl
      }
    } else {
      return ""
    }

  }

  async RefreshPanier() {
    //Aller chercher le panier dans le local storage
    this.Panier = JSON.parse(localStorage.getItem("Panier")!)

    if (this.Panier != null) {
      //Aller chercher les produits du panier
      this.Produits = this.Panier.map(d => d.produit)

      this.Produits.map(d=>{
        d.Quantite = this.Panier.find(x=>x.produit.id == d.id)?.quantite!
      })

      this.sortVariations()

      this.prixPanier = this.GetTotalPanier()
    }
  }

  sortVariations(): void {
    this.Produits.forEach(produit => {
      if (produit.variationsProduits && produit.variationsProduits.length > 0) {
        produit.variationsProduits = produit.variationsProduits
          .map(variation => {
            const match = variation.nomVariation.match(/^(\d+)ml(?:,(\w+))?$/i);
            return match
              ? { original: variation, volume: parseInt(match[1], 10), color: match[2] || '' }
              : { original: variation, volume: null, color: variation.nomVariation.toLowerCase() };
          })
          .sort((a, b) => {
            if (a.volume !== null && b.volume !== null) {
              return a.volume - b.volume || a.color.localeCompare(b.color);
            }
            if (a.volume === null && b.volume === null) {
              return a.color.localeCompare(b.color);
            }
            return a.volume === null ? 1 : -1; // Place les couleurs seules après les volumes
          })
          .map(({ original }) => original);
      }
    });
  }


  GetTotalPanier() {
    let prix: number = 0
    if (this.Produits == null) {
      return 0
    } else {
      this.Produits.forEach(p => {
        if (p.variationsProduits.length > 0) {
          prix += p.variationProduit.prix * p.Quantite
        } else {
          prix += p.prix * p.Quantite
        }
      });
    }

    return prix
  }

  getTotalProduit(IdProduit: number) {
    return this.Produits.find(x => x.id == IdProduit)!.prix * this.Panier.find(x => x.produit.id == IdProduit)!.quantite
  }

  changeQuantite(event: any, IdProduit: number) {
    //Si la quantité est à zéro, on supprime le produit du panier
    if (event == 0) {
      this.Panier = this.Panier.filter(x => x.produit.id != IdProduit)
      localStorage.setItem("Panier", JSON.stringify(this.Panier))
    } else {
      this.Panier.find(x => x.produit.id == IdProduit)!.quantite = event
      localStorage.setItem("Panier", JSON.stringify(this.Panier))
    }

    this.RefreshPanier()
  }

  GetTotalQuantite() {
    if (this.Panier == null) {
      return 0
    } else {
      return this.Panier.map(x => x.quantite).reduce((a, b) => a + b, 0)
    }
  }

  calculerTVA() {
    return this.GetTotalPanier() * 0.20
  }

  ClearPanier() {
    this.Panier = []
    localStorage.removeItem("Panier")
  }

  async AjouterProduitPanier(produit: Produit) {

    let panierString = localStorage.getItem("Panier")
    let produitsPanier: Produit[] = []
    this.Panier = []

    if (panierString != null) {
      //Récupérer le panier actuel
      this.Panier = JSON.parse(localStorage.getItem("Panier")!)
      produitsPanier = this.Panier.map(d=>d.produit)
    }

    //Si le produit est déjà dans le panier, on incrémente la quantité
    if (this.Panier.find(x => x.produit.id == produit.id)) {
      //Si le produit n'est pas une crémation
      if (produit.produitCategories.some(categorie => categorie.categorieProduit.nom != "Crémation")) {
        this.Panier.find(x => x.produit.id == produit.id)!.quantite++
      }
    } else {

      //Si c'est une crémation
      if (produit.id == 844 || produit.id == 845) {
        //Si dans le panier iln'y a pas déja un produit de catégorie crémation
        if (!produitsPanier.some(x => x.id == 844) && !produitsPanier.some(x => x.id == 845)) {
          this.Panier.push(new PanierModel(produit, 1))
        }
      } else {
        this.Panier.push(new PanierModel(produit, 1))
      }

      localStorage.setItem("Panier", JSON.stringify(this.Panier))

      this.RefreshPanier()
    }

    //#endregion

  }
}