<app-loader *ngIf="loading"></app-loader>
<div class="ContainerAll">
    <div class="LogoHautChat">
        <img src="../../assets/Connexion/chat.png" alt="logoChat">
    </div>
    <div class="LogoPhoenixMilieu">
        <img src="https://www.monphoenix.fr/wp-content/uploads/2024/11/logo-blanc.png" alt="logoChat">
    </div>
    <form id="form" class="login-form" *ngIf="!isMdpOublie && !this.is2A">

        <div class="row">
            <p>Bienvenue, merci de vous identifier</p>

        </div>
        <div class="row">
            <kendo-label class="lblTxt" text="Email" for="email"></kendo-label>
            <kendo-textbox id="email" [(ngModel)]="data.email" name="email" required></kendo-textbox>
        </div>
        <br>
        <div class="row">
            <kendo-label class="lblTxt" text="Mot de passe" for="mdp"></kendo-label>
            <kendo-textbox id="mdp" type="password" [(ngModel)]="data.mdp" name="mdp" required></kendo-textbox>
        </div>
        <br>
        <p *ngIf="this.MessageErreur !=''" class="alert alert-danger" style="background-color: red; color: black;">
            {{MessageErreur}}</p>
        <div class="row">
            <kendo-button type="submit" id="btnConnexion" (click)="onSubmit()">Se connecter</kendo-button>
        </div>
        <br>


    </form>
    <form id="form" class="login-form" *ngIf="isMdpOublie">

        <div class="row">
            <p>Veuillez saisir votre email afin de réinitialiser votre mot de passe</p>

        </div>
        <div class="row">
            <kendo-label class="lblTxt" text="Email" for="email"></kendo-label>
            <kendo-textbox id="email" [(ngModel)]="dataPerteMDP.email" name="email" required></kendo-textbox>
        </div>
        <br>
        <p *ngIf="this.MessageErreur !=''" class="alert alert-danger" style="background-color: red; color: black;">
            {{MessageErreur}}</p>
        <p *ngIf="this.MessageSucces !=''" class="alert alert-sucess" style="background-color: green; color: white;">
            {{MessageSucces}}</p>
        <div class="row" *ngIf="this.MessageSucces ==''">
            <kendo-button type="submit" id="btnConnexion" (click)="onSubmitMdpOublie()">Réinitialiser</kendo-button>
        </div>
        <br>


    </form>
    <form autocomplete="off" *ngIf="this.is2A" (submit)="onSubmit2A()">
        <div class="ContainerField2A" *ngIf="this.is2A">
            <div class="row">
                <p>Veuillez saisir le code de double authentification</p>

            </div>
            <br>
            <div class="Div2A">
                <br>

                <input type="tel" autofocus onkeypress="return event.charCode>=48 && event.charCode<=57"
                    inputmode="numeric" pattern="\d*" maxlength="1" tabindex="1" name="Code2A1" id="Code2A1"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="Code2A1" class="Code2A text-center"
                    (keyup)="FocusSuivant(1,$event)">

                <input type="tel" onkeypress="return event.charCode>=48 && event.charCode<=57" maxlength="1"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="Code2A2" tabindex="2" name="Code2A2" id="Code2A2"
                    class="Code2A text-center" (keyup)="FocusSuivant(2,$event)">

                <input type="tel" onkeypress="return event.charCode>=48 && event.charCode<=57" maxlength="1"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="Code2A3" tabindex="3" name="Code2A3" id="Code2A3"
                    class="Code2A text-center" (keyup)="FocusSuivant(3,$event)">


                <input type="tel" onkeypress="return event.charCode>=48 && event.charCode<=57" maxlength="1"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="Code2A4" tabindex="4" name="Code2A4" id="Code2A4"
                    class="Code2A text-center" (keyup)="FocusSuivant(4,$event)">


                <input type="tel" onkeypress="return event.charCode>=48 && event.charCode<=57" maxlength="1"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="Code2A5" tabindex="5" name="Code2A5" id="Code2A5"
                    class="Code2A text-center" (keyup)="FocusSuivant(5,$event)">


                <input type="tel" onkeypress="return event.charCode>=48 && event.charCode<=57" maxlength="1"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="Code2A6" tabindex="6" name="Code2A6" id="Code2A6"
                    class="Code2A text-center" (keyup)="FocusSuivant(6,$event)">

            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <div class="alert alert-danger" *ngIf="MessageErreur!=''">{{MessageErreur}}</div>
                    <div class="alert alert-success" *ngIf="messageSucces!='' && MessageErreur == ''">{{messageSucces}}
                    </div>
                </div>
            </div>
        </div>
    </form>


    <p id="MdpOubli" (click)="UpdateMdpOublie()">Mot de passe oublié</p>
    <div class="LogoBasChien">
        <img src="../../assets/Connexion/chien.png" alt="LogoChien">
    </div>
</div>

<ngx-spinner bdColor="rgba(197, 191, 194, 0.8)" size="default" color="red" type="ball-clip-rotate"></ngx-spinner>