import { Component, OnInit } from '@angular/core';
import { SocieteService } from '../../_services/SocieteService';
import { CabinetVeterinaire } from '../../_models/CabinetVeterinaire';
import { CommandAction } from '@progress/kendo-angular-grid/editing/edit.service';
import { Commande } from '../../_models/Commande';
import { CommandeService } from '../../_services/CommandeService';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';

@Component({
  selector: 'app-parc-phoenix-admin',
  templateUrl: './parc-phoenix-admin.component.html',
  styleUrls: ['./parc-phoenix-admin.component.scss']
})
export class ParcPhoenixAdminComponent implements OnInit {

  SitePhoenix: CabinetVeterinaire = new CabinetVeterinaire()
  loading: boolean = false;
  Commandes: Commande[] = []

  public gridData!: GridDataResult;
  public gridState: State = {
    skip: 0,
    take: 25,
  };

  constructor(private _societeService: SocieteService, private _commandeService: CommandeService) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.Commandes = (await this._commandeService.GetCommandeByStatutId(3)).objet

    this.Commandes = this.Commandes.map(commande => ({
      ...commande,
      dateArrive: new Date(commande.asso_StatutCommande_Commande.filter(x => x.statutCommandeId == 3)[0].date),
      nbJourDeces: new Date().getDate() - new Date(commande.animal.dateDeces).getDate()

    }))

    this.dataStateChange(this.gridState);

    this.loading = false;
  }

  public async dataStateChange(state: State) {
    this.gridState = state;
    await this.ChargerTableau();
  }
  ///charge les utilisateurs dans le tableau
  public async ChargerTableau() {
    // Init des données pour la grille
    this.gridData = process(this.Commandes, this.gridState);
  }

}
