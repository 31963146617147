import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../_services/GlobalService';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { SiteCabinetVeterinaire } from '../../../_models/SiteCabinetVeterinaire';
import { SocieteService } from '../../../_services/SocieteService';
import { filter, pairwise } from 'rxjs/operators';

@Component({
  selector: 'app-detail-cabinet-tournee',
  templateUrl: './detail-cabinet-tournee.component.html',
  styleUrls: ['./detail-cabinet-tournee.component.scss']
})
export class DetailCabinetTourneeComponent implements OnInit {

  IdEtablissement: number;
  SiteCabinet: SiteCabinetVeterinaire = new SiteCabinetVeterinaire();

  constructor(public _GlobalService: GlobalService, private actRoute: ActivatedRoute, private _SocieteService: SocieteService, private router: Router) {
    this.IdEtablissement = this.actRoute.snapshot.params['Id'];
  }

  async ngOnInit(): Promise<void> {
    this.SiteCabinet = (await this._SocieteService.GetEtablissementById(this.IdEtablissement)).objet

    //on archivés, dontla crémation n'ets pas finie, qui ont deux lignes dans assoStatutCommande et dont la dernière ligne à le statut 2
    this.SiteCabinet.commandes = this.SiteCabinet.commandes
      .filter(x => !x.archiver && !x.isCremationTermine
        && (x.asso_StatutCommande_Commande.length == 1 || x.asso_StatutCommande_Commande.length == 2))
  }

  OuvrirQrCodeMulti() {
    this.router.navigateByUrl("QrCodeMulti")
  }

  GetNbAnimauxRecupere(): number {
    //Ce sont toutes les commandes non archivés, dontla crémation n'ets pas finie, qui ont deux lignes dans assoStatutCommande et dont la dernière ligne à le statut 2
    return this.SiteCabinet.commandes.filter(x => !x.archiver && !x.isCremationTermine && x.asso_StatutCommande_Commande.length == 2 && x.asso_StatutCommande_Commande[1].statutCommandeId == 2).length

  }
}