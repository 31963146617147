import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../_services/GlobalService';
import { UtilisateurService } from '../../../_services/UtilisateurService';
import { PanierModel } from '../../../_models/PanierModel';
import { Produit } from '../../../_models/Produit';
import { ProduitService } from '../../../_services/ProduitService';
import { PanierService } from '../../../_services/PanierService';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ClientService } from '../../../_services/ClientService';

@Component({
  selector: 'app-header-client',
  templateUrl: './header-client.component.html',
  styleUrls: ['./header-client.component.scss']
})
export class HeaderClientComponent implements OnInit {

  private test = true

  constructor(public _GlobalService: GlobalService,
    private _UtilisateurService: UtilisateurService,
    private _ProduitService: ProduitService,
    public _PanierService: PanierService,
    private _ClientService: ClientService,
    private router: Router) { }

  async ngOnInit(): Promise<void> {
    this.chargerUtilisateur()

    if(this.test){
      this._PanierService.RefreshPanier()
      this.test = false
    }
  }

  public deconnexion() {
    this._UtilisateurService.Deconnexion()
  }

  private async chargerUtilisateur() {
    this._GlobalService.client = (await this._UtilisateurService.GetInfosUser()).objet;
    this._GlobalService.client!.PrenomNom = this._GlobalService.client?.prenom + " " + this._GlobalService.client?.nom
  }

  GoToMonCompte() {
    location.href = "/client/moncompte"
  }

  GoToPanier() {
    location.href = "/client/panier"
  }
  GoToMemorial() {
    location.href = "/client/memorial"
  }
  GoToAccueil() {
    location.href = "/client/accueil"
  }

  async RecupQuiSommesNous() {
    this.router.navigate(['/client/quisommesnous']);
  }


}
