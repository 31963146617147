import { OptionVariation } from "./OptionVariation"
import { Produit } from "./Produit"


export class PanierModel {
    produit: Produit = new Produit()
    quantite: number = 0
    

    constructor(idProduit: Produit, quantite: number) {
        this.produit = idProduit
        this.quantite = quantite
    }
}