import { Component, Input, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ClientViewComponent } from '../../Admin/_Views/client-view/client-view.component';
import { GlobalService } from '../../_services/GlobalService';

@Component({
  selector: 'app-header-technicien-chauffeur',
  templateUrl: './header-technicien-chauffeur.component.html',
  styleUrls: ['./header-technicien-chauffeur.component.scss']
})
export class HeaderTechnicienChauffeurComponent {
  @Input() Titre: string = '';
  @Input() EnableScanIcon: boolean = true;

  role: string | null = localStorage.getItem('Role');
  
  constructor(public router: Router, private _location: Location, private _DialogService: DialogService,private _Globalservice:GlobalService) { }

  public Retour() {
    this._location.back();
  }

  public ChoisirQrCode() {
    this.router.navigateByUrl("QrCodeSimple")
  }
}