<div id="Footer">
    <br><br>
    <div id="ContainerFooter">
        <img id="LogoFooter" src="../../../assets/Connexion/LogoPhoenixTexte.png">
        <div id="ContainerCoordonnes">
            <p>Adresse postale</p>
            <p>85300 Challans</p>
            <p>02 51 49 31 31</p>
        </div>
        <button id="BtnFooterContact" (click)="GoToSite('https://www.monphoenix.fr/contact/')">Nous contacter</button>
    </div>
    <div id="Credentials">
        <p>PHOENIX © 2023 - Tous droits réservés.</p>
    </div>
    <br>
    <div id="MentionsLegales">
        <p class="LienClickable" (click)="GoToSite('https://www.monphoenix.fr/qui-sommes-nous/')">Qui sommes nous</p>
        <p class="LienClickable" (click)="GoToSite('https://www.monphoenix.fr/')">Mon Phoenix</p>
        <p class="LienClickable" (click)="GoToSite('https://www.monphoenix.fr/rgpd/')">Politique de confidentialité</p>
        <p class="LienClickable" (click)="GoToSite('https://www.monphoenix.fr/contact/')">Contact</p>
        <p class="LienClickable" (click)="GoToSite('https://www.monphoenix.fr/mentions-legales/')">Mentions légales</p>
    </div>
</div>