<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <h2>Parc des corps de l'établissement {{this.site.CabinetVille}}</h2>
        </div>
        <br>
        <div class="row">
            <div class="col-12">
                <div class="grid-wrapper">
                    <kendo-grid [data]="gridData" [sortable]="true" (dataStateChange)="dataStateChange($event)"
                        [sort]="gridState.sort" [columnMenu]="true" [pageSize]="gridState.take" [skip]="gridState.skip"
                        [height]="500" [pageable]="true" [filter]="gridState.filter" [filterable]="true">
                        
                        <kendo-grid-column field="detailCremation.sacId" title="N° d'étiquette"> </kendo-grid-column>
                        <kendo-grid-column field="client.PrenomNom" title="Nom du propriétaire"> </kendo-grid-column>
                        <kendo-grid-column field="client.telephone" title="N° de téléphone du propriétaire"> </kendo-grid-column>
                        <kendo-grid-column filter="date" field="animal.dateDeces" title="Date de déces">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{dataItem.animal.dateDeces | date:'dd/MM/yyyy'}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="animal.nom" title="Nom de l'animal"> </kendo-grid-column>
                        <kendo-grid-column field="animal.poids" title="Poids (KG)"> </kendo-grid-column>
                        <kendo-grid-column field="animal.categorieAnimal.nom" title="Espèce">
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
</div>