<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <form #infoForm="ngForm" name="Form">
                <div *ngIf="this.messageErreurInformation != ''" class="alert alert-danger">
                    {{messageErreurInformation}}
                </div>
                <kendo-tabstrip (tabSelect)="onSelect($event)" #tabstrip>

                    <kendo-tabstrip-tab title="Animal" [selected]="true" #tabInformation>
                        <ng-template kendoTabContent>
                            <br>
                            <h2>Informations de l'animal</h2>
                            <br>
                            <br>
                            <div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Nom *">
                                            <kendo-textbox placeholder="Nom" name="nom"
                                                [(ngModel)]="this.commande.animal.nom" [clearButton]="true" required>
                                                <ng-template kendoTextBoxSuffixTemplate>
                                                    <kendo-icon name="pencil"></kendo-icon>
                                                </ng-template>
                                            </kendo-textbox>
                                        </kendo-label>
                                    </div>
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Date de décès *">
                                            <kendo-datepicker required [(ngModel)]="this.commande.animal.dateDeces"
                                                name="dateDeces"></kendo-datepicker>
                                        </kendo-label>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Catégorie *">
                                            <kendo-dropdownlist required valueField="nom" textField="nom"
                                                name="categorieAnimal" [data]="CategoriesAnimal"
                                                [(ngModel)]="this.commande.animal.categorieAnimal" required
                                                [filterable]="true"
                                                (filterChange)="handleFilterCategorieAnimal($event)">
                                            </kendo-dropdownlist>
                                        </kendo-label>
                                    </div>
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Sexe *">
                                            <kendo-dropdownlist required name="sexe" [data]="Sexes"
                                                [(ngModel)]="this.commande.animal.sexe" required>
                                            </kendo-dropdownlist>
                                        </kendo-label>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-6">
                                        <kendo-label class="k-form" text="Poids (Kg)*"></kendo-label>
                                        <kendo-numerictextbox [format]="'n2'" name="poids" [min]="0" [step]="0.1"
                                            required [(ngModel)]="this.commande.animal.poids">
                                        </kendo-numerictextbox>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-12">
                                        <kendo-label class="k-form" text="Description :">
                                            <kendo-textarea [(ngModel)]="this.commande.animal.description" [rows]="5"
                                                name="commentaire">
                                            </kendo-textarea>
                                        </kendo-label>
                                    </div>
                                </div>
                            </div>
                            <br>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="Crémation" [selected]="false" #tabInformation>
                        <ng-template kendoTabContent>
                            <br>
                            <h2>Informations sur la crémation</h2>
                            <br>
                            <div class="row">
                                <div class="col-md-4">
                                    <kendo-label class="k-form" text="Type de crémation">
                                        <kendo-dropdownlist required valueField="nom" textField="nom"
                                            [data]="TypesCremation"
                                            [(ngModel)]="this.commande.detailCremation.typeCremationInitial"
                                            name="typeCremationInitial">
                                        </kendo-dropdownlist>
                                    </kendo-label>
                                </div>
                                <div class="col-md-4" *ngIf="!this.nouveau">
                                    <kendo-label class="k-form" text="Type de crémation final*">
                                        <kendo-dropdownlist required valueField="nom" textField="nom"
                                            [data]="TypesCremation"
                                            [(ngModel)]="this.commande.detailCremation.typeCremationFinal"
                                            name="typeCremationFinal">
                                        </kendo-dropdownlist>
                                    </kendo-label>
                                </div>
                            </div>
                            <br>
                            <br>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </form>
        </div>
    </div>
</div>
<app-loader *ngIf="loading"></app-loader>