import { Component, Input, OnInit } from '@angular/core';
import { TourneeEtablissement } from '../../../_models/TourneeEtablissement';
import { GlobalService } from '../../../_services/GlobalService';
import { Router } from '@angular/router';
import { Tournee } from '../../../_models/Tournee';
import { DataModelGetSacAffecter } from '../../../_models/DataModelController/DataModelGetSacAffecter';
import { SacService } from '../../../_services/SacService';
import { ReponseSacTypeSac } from '../../../_models/DataModelController/ReponseSacTypeSac';
import { ThisReceiver } from '@angular/compiler';

@Component({
  selector: 'app-carte-tournee',
  templateUrl: './carte-tournee.component.html',
  styleUrls: ['./carte-tournee.component.scss']
})
export class CarteTourneeComponent implements OnInit {
  @Input() Tournees: TourneeEtablissement[] = new Array();

  reponseSacTypeSac: ReponseSacTypeSac[] = []
  data: DataModelGetSacAffecter = new DataModelGetSacAffecter()
  constructor(private _SacService: SacService, private _GlobalService: GlobalService, private _router: Router) { }

  async ngOnInit() {

    //La date de début est égal à la date actuelle -1 mois
    let date = new Date()
    date.setDate(date.getDate() - 7);
    this.data.dateDebut = date

    let reponse: ReponseSacTypeSac[] = (await this._SacService.GetSacAffecter(this.data)).objet

    //Filtrer la reponse en ne prenant que les etablissements présents dans la touenee
    this.reponseSacTypeSac = reponse.filter(x => this.Tournees.some(tournee => tournee.siteCabinetVeterinairetId == x.site.id))
  }


  DetailTourneeCabinet(tournee: TourneeEtablissement) {
    this._GlobalService.tournee = tournee;
    this._router.navigateByUrl("Chauffeur/Tournee/Etablissement/" + tournee.siteCabinetVeterinairetId)
  }

  isTourneeValid(tournee: TourneeEtablissement): boolean {
    return tournee.siteCabinetVeterinaire.commandes.some(commande =>
      commande.asso_StatutCommande_Commande.some(x => x.statutCommandeId !== 1)
    );
  }

  getAnimauxRecupere(tournee: TourneeEtablissement): number {
    //Prendre les commandes non finies et pas archivées
    let commandes = tournee.siteCabinetVeterinaire.commandes.filter(commande => !commande.archiver && !commande.isCremationTermine)

    //Dans ces commandes, prendre seulement les commandes qui n'ont qu'une ligne dans assoStatutCommande et que cette ligne à le statut 1
    let nb = commandes.filter(commande => commande.asso_StatutCommande_Commande.length == 1 && commande.asso_StatutCommande_Commande[0].statutCommandeId == 1).length

    return nb
  }

  getNbSacsConsomes(tournee: TourneeEtablissement, taille: string): number {

    let reponse = this.reponseSacTypeSac.filter(x => x.site.id == tournee.siteCabinetVeterinairetId)[0]

    if (reponse != null) {

      switch (taille) {
        case "S":
          return reponse.nbTailleS
        case "M":
          return reponse.nbTailleM
        case "L":
          return reponse.nbTailleL
        default: return 0
      }
    }else{
      return 0
    }

  }

}