<app-header-client></app-header-client>
<app-loader *ngIf="loading"></app-loader>
<div id="ContainerMonCompteAnimal">
    <div id="MonCompte">

        <p id="TxtParametres"> PARAMÈTRES</p>
        <p id="TxtMonCompte"> MON COMPTE</p>

        <div class="ContainerLabelInput">
            <label class="label" for="Nom">Nom</label>
            <input class="InputForm" type="text" id="Nom" name="Nom" [(ngModel)]="this.Client.nom">
        </div>
        <div class="ContainerLabelInput">
            <label class="label" for="Prenom">Prénom</label>
            <input class="InputForm" type="text" id="Prenom" name="Prenom" [(ngModel)]="this.Client.prenom">
        </div>
        <div class="ContainerLabelInput">
            <label class="label" for="Email">Email</label>
            <input class="InputForm" type="text" id="Email" name="Email" [(ngModel)]="this.Client.email">
        </div>
        <div class="ContainerLabelInput">
            <label class="label" for="Telephone">Téléphone</label>
            <input class="InputForm" type="text" id="Telephone" name="Telephone" [(ngModel)]="this.Client.telephone">
        </div>
        <div class="ContainerLabelInput">
            <label class="label" for="Adresse">Adresse</label>
            <input class="InputForm" type="text" id="Adresse" name="Adresse" [(ngModel)]="this.Client.adresse">
        </div>
        <div class="ContainerLabelInput">
            <label class="label" for="ville">Ville</label>
            <input class="InputForm" type="text" id="ville" name="ville" [(ngModel)]="this.Client.ville">
        </div>
        <div class="ContainerLabelInput">
            <label class="label" for="CodePostal">Code Postal</label>
            <input class="InputForm" type="text" id="CodePostal" name="CodePostal" [(ngModel)]="this.Client.codePostal">
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <div class="alert alert-danger" *ngIf="MessageErreur!=''">{{MessageErreur}}</div>
                <div class="alert alert-success" *ngIf="MessageSucces!='' && MessageErreur == ''">{{MessageSucces}}
                </div>
            </div>
        </div>
        <button id="BtnEnregistrer" (click)="UpdateClient()">SAUVEGARDER <span> ➞</span></button>
    </div>
    <div id="Animal">

        <div id="ContainerMonAnimal">
            <div id="ContainerInfosAnimal">
                <p id="TxtMonAnimal">Mon animal</p>
                <p id="TxtNomAnimal" >{{this.Client.commandes[0].animal.nom}}
                </p>
                <p id="TxtDateAnimal">Date du décès {{this.Client.commandes[0].animal.dateDeces.toLocaleDateString()}}
                </p>
                <p id="TxtCategorieAnimal">Catégorie :
                    {{this.Client.commandes[0].animal.categorieAnimal.nom}}
                </p>
                <p id="TxtSexeAnimal">Sexe : {{this.Client.commandes[0].animal.sexe}}</p>
                <button id="BtnEnregistrer" class="AllEspace"
                    (click)="GoToFicheAnimal(this.Client.commandes[0])">COMPLÉTER LA FICHE <span> ➞</span></button>
            </div>
            <div id="ContainerBtnAnimal">
                <div id="ImgBackground"></div>
                <img id="ImgAnimal" *ngIf="this.Client.commandes[0].animal.photo1 != ''"
                    src="{{this.Client.commandes[0].animal.photo1}}" alt="PhotoAnimal">
                <img id="ImgAnimal" *ngIf="this.Client.commandes[0].animal.photo1 == ''"
                    src="../../../../assets/Utilisateur/LogoAjouterImageMemorialFiche.png" alt="PhotoAnimal">
            </div>
        </div>
    </div>
</div>