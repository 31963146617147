<app-header-admin></app-header-admin>
<app-loader *ngIf="loading"></app-loader>
<div id="habilitations">
    <div class="container">
        <div class="row">
            <div class="col-md-2">
                <app-menu-admin></app-menu-admin>
            </div>
            <div class="col-md-10 p-4">
                <h1>Facturation</h1>
                <br>
                <div *ngIf="this.messageErreurInformation != ''" class="alert alert-danger">
                    {{messageErreurInformation}}
                </div>
                <br>
                <div class="row">
                    <div class="col-md-4">
                        <kendo-label text="Type de facture : *">
                            <kendo-dropdownlist name="typeFacture" [data]="types" [(ngModel)]="ChoixFacture">
                            </kendo-dropdownlist>
                        </kendo-label>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-2">
                        <kendo-floatinglabel text="Date de début *">
                            <kendo-datepicker name="dateDebut"
                                [(ngModel)]="this.DataModelFacture.dateDebut"></kendo-datepicker>
                        </kendo-floatinglabel>
                    </div>
                    <div class="col-md-2">
                        <kendo-floatinglabel text="Date de fin *">
                            <kendo-datepicker name="dateFin"
                                [(ngModel)]="this.DataModelFacture.dateFin"></kendo-datepicker>
                        </kendo-floatinglabel>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-4" style="text-align: center;">
                        <button style="width: 10vw;" kendoButton (click)="ExporterFacture()"
                            themeColor="primary">Exporter</button>
                    </div>
                </div>
                
               
            </div>
        </div>
    </div>
</div>